<template>
  <div>
    <div class="text-center">
      <h4/>
    </div>
    <b-row>
      <b-col/>
      <b-col>
        <b-form-group>
          <b-form-radio-group
            id="radios"
            :checked="settedCategoryLevel('entrega')"
            buttons
            stacked
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="
            patchStepAttributes({stepCategory:$event, stepName:'entrega'})">
            <b-form-radio value="basico">
              Correios, entrega na loja ou frete grátis
            </b-form-radio>
            <b-form-radio value="intermediario">
              Transportadora ou integrador com plugin para WordPress
            </b-form-radio>
            <b-form-radio value="avancado">
              Outro mediante análise de possibilidade
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <wysiwyg
          :html="budget.scope.steps.entrega.textoAnalise"
          placeholder="Enter something"
          @change="setStepTextoAnalise({stepName:'entrega', $event})"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  data:
      () => ({niveis: ['basico', 'intermediario', 'avancado']}),
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
      'settedCategoryLevel',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'setStepTextoAnalise',
      'patchStepAttributes',
    ]),
  },
};
</script>
