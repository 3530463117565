<template>
  <div>
    <div class="text-center">
      <h4>De modo geral, o conceito que você espera pode ser
      com botões flat de uma cor em destaque?</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="custom-form">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.conceito.categoria"
            buttons
            stacked
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="
            patchEscopoAttributes({event:$event, item:'conceito'})">
            <b-form-radio value="basico">
              Estrutura flat/line com 1 estilo tipográfico <br>
              - Google Fonts ou fonte compativel para web + <br>
              3 tamanhos de fontes e Ícones do Font Awesome - <br>
              1 cor (a cada 10 ).
            </b-form-radio>
            <b-form-radio value="intermediario">
              Patterns definidos no manual da marca com 2 estilo <br>
              tipográfico - Google Fonts ou fonte compativel para web <br>
              + 4 tamanhos de fontes e Ícones de biblioteca - 1 ou + <br>
              cores (a cada 10).
            </b-form-radio>
            <b-form-radio value="avancado">
              Elementos de ligação avançados e customização de elementos<br>
              gráficos com 3 estilo tipográfico - <br>
              Google Fonts ou fonte compativel para web + 8 tamanhos de <br>
              fontes e Ícones de biblioteca customizada - 1 ou + <br>
              cores (a cada 10).
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
    ]),
  },
};
</script>

<style lang="scss">
.custom-form /deep/ span {
  white-space: normal !important;
}
</style>
