import ecosystem from '~/ecosystem.config';
import lb from '@/services/loopback';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

const apiToken = ecosystem.apps[0].env.PIPEDRIVE_TOKEN;
// const url = ecosystem.apps[0].env.ENTERPRISE_URL;
const siField = 'c376ae92b81300d2aa9cb9f9883cf9a194f8298f';

export function getPersons() {
  return lb
    .find('/Pipedrive/Persons')
    .then(result => (result.data));
}

export function loadDeals({commit}) {
  Vue.http.get(`https://incuca.pipedrive.com/v1/deals?api_token=${apiToken}`)
    .then((data) => {
      const json = JSON.parse(data.bodyText);
      commit('setDeals', json.data);
    });
}

export function updateTeste({commit}) {
  commit('setTeste', 20);
}

export function loadDealsBySI({commit}) {
  for (let i = 173; i < 185; i += 1) {
    const getUrl = `https://api.pipedrive.com/v1/searchResults/field?term=${i}&exact_match=1&field_type=dealField&field_key=${siField}&return_item_ids=1&start=0&api_token=5aa90d3145256ca6e9fb006133c445fbcf047082`;
    Vue.http.get(getUrl)
      .then((data) => {
        let json = JSON.parse(data.bodyText);
        json = (json.data);
        if (json) {
          const id = json[0].c376ae92b81300d2aa9cb9f9883cf9a194f8298f;
          const qtd = json.length;
          commit('setValues', {
            id,
            qtd,
          });
        }
      });
  }
}

export function loadDealsDataBySI() {
  const values = [];
  for (let i = 173; i < 185; i += 1) {
    const getUrl = `https://api.pipedrive.com/v1/searchResults/field?term=${i}&exact_match=1&field_type=dealField&field_key=${siField}&return_item_ids=1&start=0&limit=99999&api_token=5aa90d3145256ca6e9fb006133c445fbcf047082`;
    const valueItem = Vue.http.get(getUrl)
      .then((data) => {
        let json = JSON.parse(data.bodyText);
        json = (json.data);
        // const id = json[0].c376ae92b81300d2aa9cb9f9883cf9a194f8298f;
        const qtd = json.length;
        return qtd;
      });
    values.push(valueItem);
  }
  return Promise.all(values);
}

export function searchPersons(_, t) {
  return lb
    .post('/Pipedrive/Persons/search', {term: t})
    .then(result => (result.data));
}

export function getDealFields({commit}) {
  return lb
    .find('/Pipedrive/DealFields')
    .then((dealFields) => {
      commit('setDealFields', dealFields.data);
    });
}

export function getDeals({commit}) {
  return lb
    .find('/Pipedrive/Deals')
    .then((deals) => {
      commit('setDeals', deals.data);
    });
}

export function searchDeals({commit}, t) {
  return lb
    .get(`/Pipedrive/Deals/Search?term=${t}`)
    .then((deals) => {
      commit('setDeals', deals.data);
    });
}
