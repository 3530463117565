<template>
  <div>
    <div class="text-center">
      <h4>Informações gerais</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group label="Qual o tipo de projeto?">
          <b-form-radio-group
            id="radios2"
            :checked="budget.budgetCategoryId"
            buttons
            button-variant="outline-primary"
            size="lg"
            name="budgetCategory"
            @input="onCategorySelect($event)">
            <b-form-radio
              v-for="budgetCategory in budgetCategories"
              :value="budgetCategory.id"
              :key="budgetCategory.id">{{ budgetCategory.name }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Cliente">
          <ic-crud-dropdown
            ref="searchPerson"
            :selected-item="selectedPerson"
            :items="persons"
            enable-search
            txt-single-entitity-name="Pessoa"
            txt-plural-entitity-name="Pessoas"
            disable-edit
            disable-add
            disable-delete
            disable-modals
            @select="onSelect"
            @search="onPersonSearch"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Referência *"
          label-for="bref">
          <b-form-input
            id="bref"
            :value="budget.id"
            type="number"
            size="lg"
            required
            readonly/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Qual o nome do cliente? *"
          label-for="cname">
          <b-form-input
            id="cname"
            :value="budget.client.name"
            type="text"
            size="lg"
            required
            @input="patchBudget({client:{name:$event}})"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Qual email do cliente?"
          label-for="cemail">
          <b-form-input
            id="cemail"
            :value="budget.client.email"
            type="email"
            size="lg"
            @input="patchBudget({client:{email:$event}})"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Qual o telefone do cliente?"
          label-for="cphone">
          <b-form-input
            id="cphone"
            :value="budget.client.phone"
            type="text"
            size="lg"
            @input="patchBudget({client:{phone:$event}})"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Qual o nome do seu projeto? *"
          label-for="pname">
          <b-form-input
            id="pname"
            :value="budget.projectName"
            type="text"
            size="lg"
            required
            @input="patchBudget({projectName:$event})"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Quantidade de horas"
          label-for="ptime">
          <b-form-input
            id="ptime"
            :value="budget.projectDescriptionValue"
            type="number"
            size="lg"
            @input="patchBudget({
              projectDescriptionValue:$event
            })"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-40">
        Descrever solicitação do cliente
        <wysiwyg
          :html="budget.projectDescription"
          placeholder="Enter something"
          @change="projectDescription"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from 'vuex';

export default {
  data() {
    return {
      extraText: 'nao',
    };
  },
  computed: {
    ...mapState('upsertBudget', [
      'budgetCategories',
    ]),
    ...mapGetters('upsertBudget', [
      'budget',
      'persons',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchBudget',
      'patchEscopoAttributes',
      'siteMap',
      'projectDescription',
    ]),
    ...mapActions('upsertBudget', [
      'getBudget',
      'getBudgetCategories',
      'getPersons',
      'searchPersons',
    ]),
    onSelect(person) {
      this.patchBudget({client: {id: person.item.id}});
      this.patchBudget({client: {name: person.item.name}});
      this.patchBudget({client: {email: person.item.email[0].value}});
    },
    onPersonSearch(term) {
      if (term.length > 4) {
        this.$refs.searchPerson.$el.querySelector(
          '.iccd-select-items'
        ).scrollTop = 0;
        this.searchPersons(term);
      }
    },
    onCategorySelect(id) {
      this.patchEscopoAttributes({event: id, item: 'category'});
      this.siteMap({id, hasBlog: false});
    },
    mapBlog($event) {
      if ($event) {
        this.siteMap({id: this.budget.budgetCategoryId, hasBlog: true});
      } else {
        this.siteMap({id: this.budget.budgetCategoryId, hasBlog: false});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div#radios2 {
    flex-wrap: wrap;
    justify-content: center;
}
.mb-40 {
  margin-bottom: 40px
}
.mbt-20 {
  margin: 20px 0;
}
</style>
<style lang="scss">
.custom-checkbox /deep/ label:before {
    background-color: #c0d1da !important;
}
</style>

