module.exports={
    "ref": "",
    "budgetCategory": "",
    "projectName": "",
    "client": {
        "name": "",
        "email": "",
        "solicitation": "",
        "history": ""
    },
    "valueBudget": "",
    "scope": {
        "mapa": {
            "stages": [
                "home"
            ],
            "blocks": [
                {
                    "id": 0,
                    "status": "home",
                    "title": "não considerar"
                }
            ]
        },
        "steps": {
            "paginas": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 1,
                    "valor": 0
                },
                "avancado": {
                    "peso": 1,
                    "valor": 0
                }
            },
            "tipografia": {
                "basico": {
                    "peso": 3,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 8,
                    "valor": 0
                }
            },
            "icones": {
                "basico": {
                    "peso": 2,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            },
            "imagens": {
                "basico": {
                    "peso": 3,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 21,
                    "valor": 0
                }
            },
            "animacoes": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "conceito": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            },
            "funcionalidades": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 8,
                    "valor": 0
                },
                "avancado": {
                    "peso": 21,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "integracoes": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 8,
                    "valor": 0
                },
                "avancado": {
                    "peso": 34,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "responsividade": {
                "basico": {
                    "peso": 3,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 8,
                    "valor": 0
                }
            },
            "blog": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 8,
                    "valor": 0
                },
                "avancado": {
                    "peso": 34,
                    "valor": 0
                }
            },
            "busca": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 3,
                    "valor": 0
                },
                "avancado": {
                    "peso": 21,
                    "valor": 0
                }
            },
            "seo": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 1,
                    "valor": 0
                },
                "avancado": {
                    "peso": 2,
                    "valor": 0
                }
            },
            "formularios": {
                "basico": {
                    "peso": 2,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 8,
                    "valor": 0
                },
                "avancado": {
                    "peso": 21,
                    "valor": 0
                }
            },
            "treinamento": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 3,
                    "valor": 0
                },
                "avancado": {
                    "peso": 8,
                    "valor": 0
                }
            },
            "rastreio": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 2,
                    "valor": 0
                },
                "avancado": {
                    "peso": 8,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "plataforma": {
                "basico": {
                    "peso": 8,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 1,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "hospedagem": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 3.21,
                    "valor": 0
                },
                "avancado": {
                    "peso": 3.51,
                    "valor": 0
                }
            },
            "migracao": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 3,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                },
                "textoAnalise": ""
            },
            "idiomas": {
                "basico": {
                    "peso": 0,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 1,
                    "valor": 0
                },
                "avancado": {
                    "peso": 1,
                    "valor": 0
                }
            },
            "pagamento": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 3,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            },
            "entrega": {
                "basico": {
                    "peso": 2,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            },
            "conteudo": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            },
            "metodologia": {
                "basico": {
                    "peso": 1,
                    "valor": 0
                },
                "intermediario": {
                    "peso": 5,
                    "valor": 0
                },
                "avancado": {
                    "peso": 13,
                    "valor": 0
                }
            }
        }
    }
}