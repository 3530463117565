import router from '@/router';
import Vue from 'vue';
import lb from '@/services/loopback';
// import { clone } from 'lodash';

// cria uma instancia modelo de budget
export function createBudget({commit, getters}) {
  const budget = Object.assign({}, getters.budgetTemplate);
  delete budget.id;
  return lb.post('/Budgets', budget)
    .then((bud) => {
      commit('setBudget', bud);
      // instancia modelo criada com sucesso.
      return bud;
    })
    .catch(Vue.toasted.error);
}

export function duplicateBudget({commit}, budget) {
  delete budget.id;
  return lb.post('/Budgets', budget)
    .then((bud) => {
      commit('setBudget', bud);
      router.push({name: 'Criar escopo', params: {id: bud.id}});
      // instancia modelo criada com sucesso.
      return bud;
    })
    .catch(Vue.toasted.error);
}

export function upsertBudget(_, budget) {
  const updatedBudget = budget;
  if (updatedBudget.id) {
    const id = updatedBudget.id;
    return lb.patch(`/Budgets/${id}`, updatedBudget);
  }
  return lb
    .post('/Budgets', updatedBudget);
}

export function getBudget(_, id) {
  return lb
    .find(`/Budgets/${id}`, {
      include: ['budgetCategory', 'installments'],
    });
}

export function saveSimulation({commit}, budget) {
  const updatedBudget = budget;
  return lb.patch(`/Budgets/${updatedBudget.id}`, updatedBudget)
    .then((bud) => {
      commit('setBudget', bud);
      Vue.toasted.success('Simulação salva com sucesso.');
      router.push({name: 'Visualizar escopo', params: {id: updatedBudget.id}});
    })
    .catch(err => console.log(err));
}

export function search(_, term) {
  return lb.find('/Budgets', {
    where: {
      or: [
        {client: {like: `%${term}%`}},
        {projectName: {like: `%${term}%`}},
        {id: {like: `%${term}%`}},
      ],
    },
    limit: 50,
    order: 'id DESC',
    include: ['budgetCategory', 'installments'],
  });
}

export function removeBudget(_, budget) {
  return lb
    .delete(`/Budgets/${budget.id}`);
}

export function getBudgetCategories() {
  return lb
    .find('/Budget_Categories');
}
