<template>
  <header class="app-header navbar">
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      variant="outline-info"
      @click="mobileSidebarToggle">
      <span class="navbar-toggler-icon"/>
    </button>
    <b-link
      class="navbar-brand"
      to="#"/>
    <button
      class="navbar-toggler sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle">
      <span class="navbar-toggler-icon"/>
    </button>
    <b-navbar-nav class="ml-auto">
      <b-button
        class="btn-transparent d-md-down-none"
        @click="signOut">
        <i class="fas fa-power-off"/>
      </b-button>
    </b-navbar-nav>
  </header>
</template>
<script>

export default {
  name: 'HeaderApp',
  methods: {
    signOut() {
      this.$store.dispatch('auth/signOut');
    },
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-hidden');
    },
    sidebarMinimize(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-minimized');
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-mobile-show');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header.navbar .navbar-brand {
  background-image: url("/static/img/incuca-insales.png");
}
</style>

