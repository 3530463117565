<template>
  <div
    v-if="budget.budgetCategoryId &&
      budget.budgetCategoryId == 1 ||
      budget.budgetCategoryId == 3 ||
      budget.budgetCategoryId == 4 ||
      budget.budgetCategoryId == 6 ||
      budget.budgetCategoryId == 14 ||
    budget.budgetCategoryId == 15">
    <!-- <b-row
      v-if="budget.scope
        && budget.scope
        && budget.scope.mapa
      && budget.scope.mapa.stages"
      class="pt-5">
      <b-col>
        <h4 class="exo2">
          <strong>Sitemap</strong>
        </h4>
        <Kanban
          :stages="budget.scope.mapa.stages"
          :blocks="budget.scope.mapa.blocks"
          class="kanban">
          <div
            v-for="block in budget.scope.mapa.blocks"
            :key="block.id"
            :slot="block.id">
            <div>
              {{ block.title }}
            </div>
          </div>
        </Kanban>
      </b-col>
    </b-row> -->
    <!-- ;quebra; -->
    <!-- <b-row
      v-if="budget.scope
      && budget.scope.steps"
      class="pt-5">
      <b-col>
        <BudgetScopeProjectTable :budget="budget" />
      </b-col>
    </b-row> -->
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2">
          <strong>Cronograma</strong>
        </h4>
        <span class="text">O cronograma será estabelecido entre o time
        após a primeira reunião do projeto. </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Kanban from '@/components/Kanban/Kanban.vue';
import BudgetScopeProjectTable from
  '@/components/budget-scope/BudgetScopeProjectTable.vue';

export default {
  components: {
    Kanban,
    BudgetScopeProjectTable,
  },
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
