<template>
  <div>
    <div
      id="report"
      class="bg-white p-5"
      style="max-width: 8.11in"
    >
      <b-container>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <h5>
              <!-- eslint-disable -->
              <span style="margin-left:-2px">IN</span><span style="font-weight:bold">CUCA</span> TECH -
              <strong>{{ currentDate }}</strong>
              <!-- eslint-enable -->
            </h5>
            <div v-if="finance ">
              <h3 class="mb-0">
                <strong>ORÇAMENTO - REF {{ budget.id }}</strong>
              </h3>
            </div>
            <div v-else>
              <h3 class="mb-0">
                <strong>SIMULAÇÃO DE ORÇAMENTO - REF {{ budget.id }}</strong>
              </h3>
            </div>
          </b-col>
          <b-col
            md="2"
            class="px-0"
          >
            <img
              class="w-100"
              src="/static/img/incuca-pb-insales.png"
            >
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col>
            <h4>
              <strong>Contato</strong>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <strong class="exo2">Projeto:</strong>
            <span class="text">{{ budget.projectName }}</span>
          </b-col>
        </b-row>
        <b-row
          v-if="budget.client.history"
          class="pt-5"
        >
          <b-col>
            <h4>
              <strong>Valor do escopo</strong>
            </h4>
            <span class="text">{{ formattedPrice }}</span>
          </b-col>
        </b-row>
        <div v-if="finance">
          <b-row class="pt-5">
            <b-col>
              <h4>
                <strong>Valor do orçamento</strong>
              </h4>
              <div class="text">{{ formattedPriceBudget }}</div>
              <br>
              <b-table
                :items="shownInstallments"
                :fields="fieldsInstallments"
                striped
                hover
              />
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row class="pt-5 ml-1">
            <b-form-group label="Selecione a forma de pagamento">
              <b-form-radio-group
                id="btnradios1"
                v-model="selected"
                :options="options"
                buttons
                name="radiosBtnDefault"
                @input="atualizaValor()"
              />
            </b-form-group>
          </b-row>
          <b-row
            v-show="selected != 'isCash' && selected != 'isMonthly'"
            class="pt-1 ml-1"
          >
            <b-table
              :items="items"
              :fields="Installments"
              striped
              hover
            />
            <table class="table table-striped table-hover juros pt-1">
              <tr>
                <th />
                <th class="bg">Juros</th>
                <th class="bg">Preço à vista</th>
                <th class="bg">Preço à prazo</th>
              </tr>
              <tr>
                <td class="bg-td">Total</td>
                <td align="center">{{ valueInterest }}</td>
                <td align="center">{{ valueInCash }}</td>
                <td align="center">{{ valueDeadline }}</td>
              </tr>
            </table>
          </b-row>
          <div v-if="selected == 'isCash' || selected == 'isMonthly'">
            <b-row class="pt-5">
              <b-col>
                <h4>
                  <strong>Preço de venda
                    {{ selected == 'isCash' ? 'à vista' : 'valor mensal' }}
                  </strong>
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Valor Inicial"
                  label-for="valueInit"
                >
                  <b-form-input
                    id="valueInit"
                    v-model="budget.valueBudget"
                    type="text"
                    size="lg"
                    @input="currentValueBudget()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="selected == 'isInstallmentsCard'">
            <b-row class="pt-5">
              <b-col>
                <h4>
                  <strong>Simulação de venda parcelado com cartão</strong>
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Taxa de juros (%)"
                  label-for="taxCard"
                >
                  <b-form-input
                    id="taxCard"
                    v-model="taxCard"
                    type="text"
                    size="lg"
                    @input="atualizaValor()"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Número de parcelas"
                  label-for="nroInstallmentCard"
                >
                  <b-form-input
                    id="nroInstallmentCard"
                    v-model="InstallmentsCard"
                    type="text"
                    size="lg"
                    @input="atualizaValor()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <em>Colocar percentual do comercial 20%</em><br>
              </b-col>
            </b-row>
          </div>
          <div v-if="selected == 'isInstallments'">
            <b-row class="pt-5">
              <b-col>
                <h4>
                  <strong>Simulação de venda a prazo (parcelas boleto)</strong>
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Taxa de juros (%)"
                  label-for="taxBillet"
                >
                  <b-form-input
                    id="taxBillet"
                    v-model="taxBillet"
                    type="text"
                    size="lg"
                    @input="atualizaValor()"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Número de parcelas"
                  label-for="nroInstallmentsBillet"
                >
                  <b-form-input
                    id="nroInstallmentsBillet"
                    v-model="InstallmentsBillet"
                    type="text"
                    size="lg"
                    @input="atualizaValor()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <em>até 5x usar 3,75%</em><br>
                <em>de 6 a 12 usar 3,50%</em><br>
                <em>de 13 a 18 parcelas usar 3,33%</em><br>
              </b-col>
            </b-row>
          </div>
          <div class="pt-5 center">
            <b-button
              v-if="budget.installments.length > 0"
              variant="primary"
              @click="updateSimulation"
            >Atualizar simulação</b-button>
            <b-button
              v-else
              variant="primary"
              @click="saveSimulation"
            >Salvar simulação</b-button>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import moment from 'moment';
import $store from '@/store';

export default {
  data() {
    return {
      InstallmentsBillet: '3',
      taxBillet: '3.75',
      InstallmentsCard: '3',
      taxCard: '3.75',
      selected: 'isCash',
      valueInterest: '',
      valueInCash: '',
      valueDeadline: '',
      options: [
        {text: 'À vista', value: 'isCash'},
        {text: 'Mensal', value: 'isMonthly'},
        {text: 'Parcelado com cartão', value: 'isInstallmentsCard'},
        {text: 'Parcelado com boleto', value: 'isInstallments'},
      ],
      fields: [
        'numero',
        'dataParcela',
        'juros',
        'amortizacao',
        'prestacao',
        'saldoDevedor',
      ],
      fieldsInstallments: ['numero', 'dataParcela', 'valor'],
      items: [],
      installments: [],
    };
  },
  computed: {
    ...mapState('budgetSimulation', ['budget', 'finance']),
    ...mapGetters('budgetSimulation', [
      'budgetTotal',
      'pointPrice',
      'shownInstallments',
    ]),
    currentDate() {
      return moment(this.budget.updated).format('DD/MM/YYYY');
    },
    dateFirstBillet() {
      if (this.budget.update) {
        return moment(this.budget.updated).format('DD/MM/YYYY');
      }
      return '';
    },
    formattedPrice() {
      return this.numberToReal(parseFloat(this.budgetTotal.price));
    },
    formattedPriceBudget() {
      return this.numberToReal(parseFloat(this.budget.valueBudget));
    },
  },
  beforeRouteEnter(to, from, next) {
    $store.dispatch('budgetSimulation/setupStore', {
      to,
      next,
    });
  },
  methods: {
    atualizaValor() {
      let installments = 0;
      if (this.selected === 'isInstallmentsCard') {
        installments = this.compoundInterest(
          this.dateFirstCard(),
          this.budget.valueBudget,
          this.taxCard,
          this.InstallmentsCard
        );
      }
      if (this.selected === 'isInstallments') {
        installments = this.compoundInterest(
          this.dateFirstBillet,
          this.budget.valueBudget,
          this.taxBillet,
          this.InstallmentsBillet
        );
      }
      return installments;
    },
    dateFirstCard() {
      if (this.budget.update) {
        return moment(this.budget.updated).format('DD/MM/YYYY');
      }
      return '';
    },
    currentValueBudget() {
      if (this.budget.valueBudget === 0 || this.budget.valueBudget == null) {
        this.budget.valueBudget = parseFloat(this.budgetTotal.price).toFixed(2);
        return this.budget.valueBudget;
      }
      return this.budget.valueBudget;
    },
    refreshTable(
      dateFirstInstallment,
      nroInstallment,
      valueInstallment,
      interestRate,
      balanceDue
    ) {
      this.items = [];
      this.items.push({
        numero: '0',
        dataParcela: '',
        juros: '',
        amortizacao: '',
        prestacao: '',
        saldoDevedor: this.numberToReal(parseFloat(balanceDue)),
      });

      for (let i = 1; i <= nroInstallment; i += 1) {
        const valueInterest = interestRate * balanceDue;
        const amort = valueInstallment - valueInterest;

        const dateInstallmentTable =
          i === 1 ?
            moment(this.budget.updated).format('DD/MM/YYYY') :
            moment(this.budget.updated)
              .add(i - 1, 'month')
              .format('DD/MM/YYYY');
        const interestTable = interestRate * balanceDue;
        const amortizationTable = amort;
        const installmentTable = valueInstallment;
        const balanceDueTable = balanceDue - amort;

        this.items.push({
          numero: i,
          dataParcela: dateInstallmentTable,
          juros: this.numberToReal(interestTable),
          amortizacao: this.numberToReal(amortizationTable),
          prestacao: this.numberToReal(installmentTable),
          saldoDevedor: this.numberToReal(balanceDueTable),
        });

        balanceDue -= amort;
      }
    },

    compoundInterest(dateFirst, value, rate, installment) {
      rate /= 100;
      const financedAmount = value;
      const interest = rate;
      // eslint-disable-next-line no-mixed-operators
      const cf = interest / (1 - 1 / (1 + interest) ** installment);
      const installmentValue = cf * financedAmount;

      this.refreshTable(
        dateFirst,
        installment,
        installmentValue,
        rate,
        financedAmount
      );

      const valueTotalServiceWithInterest = installmentValue * installment;
      const totalValueOfInterest = valueTotalServiceWithInterest - value;

      this.valueInterest = this.numberToReal(totalValueOfInterest);
      this.valueInCash = this.numberToReal(parseFloat(financedAmount));
      this.valueDeadline = this.numberToReal(valueTotalServiceWithInterest);

      return installmentValue.toFixed(2);
    },

    numberToReal(number) {
      const newNumber = number.toFixed(2).split('.');
      newNumber[0] = `R$ ${newNumber[0].split(/(?=(?:...)*$)/).join('.')}`;
      return newNumber.join(',');
    },

    saveSimulation() {
      // parcelamento
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      this.installments = [];
      for (let i = 0; i < this.items.length; i++) {
        if (i > 0) {
          this.installments.push({
            dueDate: moment(this.items[i].dataParcela, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            ),
            type: '0',
            value: this.items[i].prestacao,
            budgetId: this.$route.params.id,
          });
        }
      }
      if (this.selected === 'isInstallmentsCard') {
        // Parcelamento com cartão.
        this.$store.dispatch(
          'installment/createInstallments',
          this.installments
        );
      } else if (this.selected === 'isInstallments') {
        // Parcelamento com boleto.
        this.$store.dispatch(
          'installment/createInstallments',
          this.installments
        );
      }
      this.budget.selected = this.selected;
      this.$store.dispatch('budget/saveSimulation', this.budget);
    },
    updateSimulation() {
      if (this.budget.installments.length > 0) {
        console.log('delete');
        this.$store
          .dispatch('installment/deleteInstallments', this.budget.installments)
          .then(() => {
            console.log('após deletar');
            this.saveSimulation();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-td {
  background: #3db5f4;
  color: #ffffff;
  text-align: center;
}
.juros th.bg {
  background: #3db5f4;
  color: #ffffff;
  text-align: center;
}
.table th {
  border-top: none;
}
</style>
