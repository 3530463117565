<template>
  <div>
    <b-card
      :class="panelBackground"
      :no-body="true">
      <div class="card-body pb-0">
        <h2 class="mb-0">{{ bigNumber }}</h2>
        <p>{{ textUnderBig }}</p>
        <slot/>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @description
     * Big number to show
     */
    bigNumber: {
      type: [
        String,
        Number,
      ],
      default: 0,
    },
    /**
     * @description
     * Text to show under big number
     */
    textUnderBig: {
      type: [
        String,
      ],
      default: '',
    },
    /**
     * @description
     * Class to give panel color
     */
    panelBackground: {
      type: [
        String,
      ],
      default: '',
    },
  },
};
</script>

<style>

</style>
