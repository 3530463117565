<template>
  <div>
    <div class="text-center">
      <h4>Inserir conteúdo</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="custom">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.conteudo.categoria"
            buttons
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="onRadioSelect($event)">
            <b-form-radio value="basico">
              Básico
            </b-form-radio>
            <b-form-radio value="intermediario">
              Intermediário
            </b-form-radio>
            <b-form-radio value="avancado">
              Avançado
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="budget.scope.steps.conteudo.categoria === 'avancado'"
      class="item-content">
      <h3>Informar o número de Idiomas</h3>
      <b-form-input
        id="qtd-entregas"
        :value="budget.scope.steps.conteudo.idiomas"
        class="mb-15"
        type="number"
        size="lg"
        @input="onInput($event)"/>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
      'setStepExtraInfo',
    ]),
    onInput($event) {
      this.setStepExtraInfo({item: 'conteudo', $event});
    },
    onRadioSelect($event) {
      if ($event !== 'avancado') {
        this.setStepExtraInfo({item: 'conteudo', $event: null});
      }
      this.patchEscopoAttributes({event: $event, item: 'conteudo'});
    },
  },
};
</script>

<style lang="scss" scoped>
.custom {
  text-align: center;
}
.item-content {
  margin: 30px 0;
}
</style>

