export function chartData(state) {
  if (!state.chartData) return [];
  return state.chartData;
}

export function values(state) {
  return state.values;
}
// a sequencia tem que bater com a sequencia de chaves no pipedrive por
// por exemplo 173 é parceiro e 174 é landing-page 175 é blog e assim por diante
export function labels() {
  return [
    'Parceiro',
    'Landing-page',
    'Blog',
    'Website',
    'E-commerce',
    'EAD',
    'aplicativo',
    'Software',
    'Experimental',
    'Suporte Mensal',
    'Performance Digital',
    'Segurança Digital',
    'Suporte Extra',
    'Extra',
  ];
}
