export default {
  data: () => ({isLoading: false}),
  computed: {
    loadingClasses() {
      return {
        fa: true,
        'fa-spinner': this.isLoading,
      };
    },
  },
  filters: {
    icon(loadingClasses, icon) {
      const newClasses = {};
      newClasses[`fa-${icon}`] = !loadingClasses['fa-spinner'];
      return Object.assign(loadingClasses, newClasses);
    },
  },
};
