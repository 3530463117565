<template>
  <!-- eslint-disable max-len -->
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">
            <strong class="exo2">DESENVOLVIMENTO</strong>
          </th>
          <th scope="col">
            <strong class="exo2">ESCOLHA</strong>
          </th>
          <th scope="col">
            <strong class="exo2">OBSERVAÇÕES</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="budget.scope && budget.scope.steps.hospedagem && budget.scope.steps.hospedagem.categoria == 'inmake'">
          <td>Plataforma</td>
          <td>
            <span>
              <strong>InMake</strong>
            </span>
          </td>
          <td/>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.metodologia">
          <td>Metodologia</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.metodologia.categoria == 'linear'">
              <strong>Entrega linear</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.metodologia.categoria == 'continua'">
              <strong>Entrega contínua</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.metodologia.categoria == 'linear'">
              Reunião inicial > Design > Programação > Entrega
            </span>
            <span v-if="budget.scope && budget.scope.steps.metodologia.categoria == 'continua'">
              {{ budget.scope.steps.metodologia.entregas }} entregas
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.conteudo">
          <td>Conteúdo</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'basico'">
              Títulos das páginas e subpáginas, cabeçalho e rodapé.
              O restante do texto será de marcação no layout - Lorem ipsum
            </span>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'intermediario'">
              Cliente enviará os textos em um idioma preenchendo o documento de conteúdo durante a fase de design.
              A InCuca irá colocar o conteúdo no projeto até o início da programação.
              Válido para todos os modelos de páginas criados.
              Caso tenham páginas a serem replicadas a partir do modelo criado, a inserção de conteúdo será cotada a parte.
            </span>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'avancado'">
              Cliente enviará os textos e conteúdo de todo o website, preenchendo o documento de conteúdo durante a fase de design.
              A InCuca irá colocar o conteúdo de todo o projeto na fase de programação.
              O treinamento só ocorre após todo o conteúdo ser inserido.
            </span>
            <span v-if="budget.scope && budget.scope.steps.conteudo.categoria == 'avancado' && budget.scope.steps.conteudo.idiomas">
              <br>
              Idiomas: {{ budget.scope.steps.conteudo.idiomas }}
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.treinamento && budget.scope.steps.treinamento.horas != 0">
          <td>Treinamento</td>
          <td>
            <span>
              <strong>Horas: {{ budget.scope.steps.treinamento.horas }}</strong>
            </span>
          </td>
          <td/>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.responsividade">
          <td>Responsividade</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'basico'">
              De acordo com o tema e sem ajustes.
            </span>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'intermediario'">
              Funcionalidade de ajuste na ordem de quebra de seções (direita ou esquerda) e omissão de itens no mobile.
            </span>
            <span v-if="budget.scope && budget.scope.steps.responsividade.categoria == 'avancado'">
              Mobile first com layout construído para o mobile e adaptado para web.
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.conceito">
          <td>Conceitos</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'basico'">
              Estrutura flat/line com 1 estilo tipográfico -
              Google Fonts ou fonte compativel para web + 3 tamanhos de fontes e Ícones do Font Awesome -
              1 cor (a cada 10).
            </span>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'intermediario'">
              Patterns definidos no manual da marca com 2 estilo tipográfico -
              Google Fonts ou fonte compativel para web + 4 tamanhos de fontes e Ícones de biblioteca -
              1 ou + cores (a cada 10).
            </span>
            <span v-if="budget.scope && budget.scope.steps.conceito.categoria == 'avancado'">
              Elementos de ligação avançados e customização de elementos gráficos com 3 estilo tipográfico -
              Google Fonts ou fonte compativel para web + 6 tamanhos de fontes e Ícones de biblioteca customizada -
              1 ou + cores (a cada 10).
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.imagens">
          <td>Busca por imagens</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.imagens.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.imagens.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.imagens.categoria == 'basico'">
              Banco de imagens sem edição.
            </span>
            <span v-if="budget.scope && budget.scope.steps.imagens.categoria == 'avancado'">
              Banco de imagens com filtros/efeitos.
            </span>
            <span class="small">Observação: Limitamos a até 5 imagens por página e o cliente é
            responsável pela compra, quando necessário.</span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.migracaoDeConteudo">
          <td>Migração de conteúdo</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'basico'">
              Não há necessidade.
            </span>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'intermediario'">
              Posts entre duas plataformas WordPress.
            </span>
            <span v-if="budget.scope && budget.scope.steps.migracaoDeConteudo.categoria == 'avancado'">
              Migração de outro sistema ({{ budget.scope.steps.migracaoDeConteudo.horas }} horas): <span v-html="budget.scope.steps.migracaoDeConteudo.textoAnalise"/>
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.funcionalidades && budget.scope.steps.funcionalidades.length > 0">
          <td>Funcionalidades</td>
          <td
            v-if="budget.scope && budget.scope.steps.funcionalidades.length > 0"
            colspan="2">
            <span
              v-for="(funcionalidade, i) in budget.scope.steps.funcionalidades"
              :key="funcionalidade.id">
              <strong>{{ funcionalidade.name }} </strong> <span v-if="budget.scope.steps.funcionalidades.length-1 != i"> - </span>
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.redirects">
          <td>Redirecionamentos</td>
          <td colspan="2">
            <span>
              <strong>{{ budget.scope.steps.redirects.quantidade }}</strong> redirects
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.entrega && budget.budgetCategoryId == 4">
          <td>Entrega</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'basico'">
              Correios, entrega na loja ou frete grátis.
            </span>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'intermediario'">
              Transportadora ou integrador com plugin para WordPress.
            </span>
            <span v-if="budget.scope && budget.scope.steps.entrega.categoria == 'avancado'">
              Outro ({{ budget.scope.steps.entrega.horas }} horas): <span v-html="budget.scope.steps.entrega.textoAnalise"/>
            </span>
          </td>
        </tr>
        <tr v-if="budget.scope && budget.scope.steps.pagamento && budget.budgetCategoryId && budget.budgetCategoryId == 4 && budget.budgetCategoryId == 3 ">
          <td>Gateway</td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'basico'">
              <strong>Básico</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'intermediario'">
              <strong>Intermediário</strong>
            </span>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'avancado'">
              <strong>Avançado</strong>
            </span>
          </td>
          <td>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'basico'">
              Não incluso
            </span>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'intermediario'">
              Paypal e transferência bancária
            </span>
            <span v-if="budget.scope && budget.scope.steps.pagamento.categoria == 'avancado'">
              Outro ({{ budget.scope.steps.pagamento.horas }} horas): <span v-html="budget.scope.steps.pagamento.textoAnalise"/>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="budget.scope && budget.scope.steps.hospedagem"
      class="mt-3">
      <strong
        v-if="budget.scope && budget.scope.steps.hospedagem && budget.scope.steps.hospedagem.categoria == 'inmake'"
        class="exo2">HOSPEDAGEM e SUPORTE</strong>
      <strong
        v-else
        class="exo2">HOSPEDAGEM</strong>
      <div>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'cliente'">
          Fornecida pelo cliente<br>
          Nome: {{ budget.scope.steps.hospedagem.provedor.name }}<br>
          Descrição: {{ budget.scope.steps.hospedagem.provedor.descricao }}<br>
          Link: {{ budget.scope.steps.hospedagem.provedor.link }}
        </span>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'compartilhada'">
          InCuca Compartilhada<br>
          4GB de espaço em disco<br>
          50GB de transferência mensal<br>
          2 subdomínio<br>
          2 domínios estacionados<br>
          1 domínio de complemento<br>
          <strong>R$380/anual (parcela já inclusa no orçamento)</strong>
        </span>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'zip'">
          Não há: será enviado um .zip com os arquivos do projeto<br>
        </span>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'cloud'">
          InCuca Cloud<br>
          OS: Linux 18.04<br>
          Processamento: 1 CPU/ 1GB RAM<br>
          Armazenamento: 20GB HD SSD<br>
          <strong>R$180/mês (primeira parcela já inclusa no orçamento)</strong>
        </span>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'inmake'">
          Inclusos no contrato de suporte e hospedagem InMake<br>
        </span>
        <span v-if="budget.scope && budget.scope.steps.hospedagem.categoria == 'outro'">
          Outro ({{ budget.scope.steps.hospedagem.horas }} horas): <span v-html="budget.scope.steps.hospedagem.textoAnalise"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
