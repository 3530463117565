<template>
  <div class="sidebar">
    <SidebarHeader/>
    <SidebarForm/>
    <nav class="sidebar-nav">
      <div slot="header"/>
      <ul class="nav">
        <template v-for="(item, index) in navItems">
          <template v-if="item.title">
            <SidebarNavTitle
              :name="item.name"
              :classes="item.class"
              :wrapper="item.wrapper"
              :key="index" />
          </template>
          <template v-else-if="item.divider">
            <li
              :key="index"
              class="divider"/>
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown
                :name="item.name"
                :url="item.url"
                :icon="item.icon"
                :key="index">
                <template v-for="(childL1, index) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown
                      :name="childL1.name"
                      :url="childL1.url"
                      :icon="childL1.icon"
                      :key="index">
                      <li
                        v-for="(childL2, index) in childL1.children"
                        :key="index"
                        class="nav-item">
                        <SidebarNavLink
                          :name="childL2.name"
                          :url="childL2.url"
                          :icon="childL2.icon"
                          :badge="childL2.badge"
                          :variant="item.variant" />
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem
                      :classes="item.class"
                      :key="index">
                      <SidebarNavLink
                        :name="childL1.name"
                        :url="childL1.url"
                        :icon="childL1.icon"
                        :badge="childL1.badge"
                        :variant="item.variant" />
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem
                :classes="item.class"
                :key="index">
                <SidebarNavLink
                  :name="item.name"
                  :url="item.url"
                  :icon="item.icon"
                  :badge="item.badge"
                  :variant="item.variant" />
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot/>
    </nav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter.vue';
import SidebarForm from './SidebarForm.vue';
import SidebarHeader from './SidebarHeader.vue';
import SidebarMinimizer from './SidebarMinimizer.vue';
import SidebarNavDropdown from './SidebarNavDropdown.vue';
import SidebarNavLink from './SidebarNavLink.vue';
import SidebarNavTitle from './SidebarNavTitle.vue';
import SidebarNavItem from './SidebarNavItem.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle('open');
    },
  },
};
</script>

<style lang="css">
.nav-link {
  cursor: pointer;
}
</style>
