import Vue from 'vue';

// atualiza a model do budget
export function upsert({dispatch, commit}, $event) {
  return dispatch('budget/upsertBudget', $event, {root: true})
    .then((bud) => {
      commit('setBudget', bud);
      return bud;
    })
    .catch(Vue.toasted.error);
}

export function getBudget({dispatch, commit}, id) {
  return dispatch('budget/getBudget', id, {root: true})
    .then((budget) => {
      commit('setBudget', budget);
    });
}

export function getBudgetCategories({commit, dispatch}, id) {
  return dispatch('budget/getBudgetCategories', id, {root: true})
    .then((budgetCategories) => {
      budgetCategories = budgetCategories
        .filter(cat => cat.id >= 13 || cat.id === 2 || cat.id === 8 || cat.id === 17);
      commit('setBudgetCategories', budgetCategories);
    });
}

export function getPersons({commit, dispatch}) {
  dispatch('pipedrive/getPersons', null, {root: true})
    .then((persons) => {
      commit('setPersons', persons);
    });
}

export function searchPersons({commit, dispatch}, term) {
  dispatch('pipedrive/searchPersons', term, {root: true})
    .then((persons) => {
      commit('setPersons', persons);
    });
}

export function searchHospedagens({commit, dispatch}, term) {
  dispatch('hospedagem/searchHospedagens', term, {root: true})
    .then((hospedagens) => {
      commit('hospedagens', hospedagens);
    });
}

export function getPlugins({commit, dispatch}) {
  dispatch('plugin/getPlugins', null, {root: true})
    .then((plugins) => {
      commit('plugins', plugins);
    });
}

export function getHospedagens({commit, dispatch}) {
  dispatch('hospedagem/getHospedagens', null, {root: true})
    .then((hospedagens) => {
      commit('hospedagens', hospedagens);
    });
}

export function onRouteEnter({dispatch}, {to, next}) {
  Promise.all([
    dispatch('getBudgetCategories', to.params.id),
    dispatch('getBudget', to.params.id),
    dispatch('getPlugins'),
    dispatch('getHospedagens'),
    dispatch('getPersons'),
  ])
    .then(
      () => next()
    );
}

export function onRouteUpdate({dispatch}, {params}) {
  dispatch('getBudgetCategories', params.id);
  dispatch('getBudget', params.id);
  dispatch('getPlugins');
  dispatch('getHospedagens');
  dispatch('getPersons');
}

export function createPlugin({dispatch, state, commit}) {
  dispatch('plugin/upsertPlugin', state.plugin, {root: true});
  commit('plugin', {});
  dispatch('getPlugins');
}

export function createHospedagem({dispatch, state, commit}) {
  dispatch('hospedagem/upsertHospedagem', state.hospedagem, {root: true});
  commit('hospedagem', {});
  dispatch('getHospedagens');
}
