<template>
  <div class="app">
    <HeaderApp/>
    <div class="app-body">
      <Sidebar :nav-items="nav" />
      <main class="main">
        <breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view />
        </div>
      </main>
    </div>
    <FooterApp/>
  </div>
</template>

<script>
import HeaderApp from '@/components/HeaderApp.vue';
import Sidebar from '@/components/Sidebar.vue';
import FooterApp from '@/components/FooterApp.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

import nav from '../../_nav';

export default {
  components: {
    HeaderApp,
    Sidebar,
    FooterApp,
    Breadcrumb,
  },
  data() {
    return {
      nav: nav.items,
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched;
    },
  },
};
</script>

<style lang="css">
.breadcrumb li span{
  text-transform: capitalize;
}

</style>
