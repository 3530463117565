<template>
  <div>
    <!-- eslint-disable -->
    <div class="mb-4" style="max-width: 8.6in">
      <b-button
        variant="primary"
        @click="goToEdit"
      >Editar</b-button>
      <b-button
        variant="primary"
        @click="goToSimulation"
      >Simulação</b-button>
      <b-button
        variant="dark"
        @click="generatePDF"
      >Download em PDF</b-button>
      <b-button
        variant="success"
        @click="saveInnerHTML"
      >Salvar</b-button>
      <b-button
        variant="danger"
        class="float-right"
        @click="deleteInnerHTML"
      >Desfazer</b-button>
    </div>
    <b-row class="mb-4">
      <b-col class="ecommerce">Para quebrar páginas, adicione no texto: ;quebra;</b-col>
    </b-row>
    <div
      v-if="budget.scopeInnerHTML"
      id="report"
      contenteditable="true"
      class="bg-white p-5 mb-5"
      style="max-width: 8.6in;font-family:'Roboto'; border: 1px solid black"
      v-html="budget.scopeInnerHTML"
    />
    <div
      v-else
      id="report"
      contenteditable="true"
      class="bg-white p-5 mb-5"
      style="max-width: 8.6in;font-family:'Roboto'; border: 1px solid black"
    >
      <b-container>
        <partial-header :budget="budget" />
        <div v-if="budget.client">
          <b-row class="pt-5">
            <b-col>
              <h4 class="exo2">
                <strong>Contato</strong>
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="budget.client.name">
              <strong class="exo2">Nome:</strong>
              <span class="text">{{ budget.client.name }}</span>
            </b-col>
            <b-col v-if="budget.client.email">
              <strong class="exo2">E-mail:</strong>
              <span class="text">{{ budget.client.email }}</span>
            </b-col>
            <b-col v-if="budget.client.phone">
              <strong class="exo2">Telefone:</strong>
              <span class="text">{{ budget.client.phone }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong class="exo2">Projeto:</strong>
              <span class="text">{{ budget.projectName }}</span>
            </b-col>
          </b-row>
        </div>
        <budget-budget-scope-pesquisa
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Pesquisa'"
        />
        <budget-scope-evolucao-digital
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Evolução'"
        />
        <budget-scope-performance-digital
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Performance Digital'"
        />
        <budget-scope-in-make
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'InMake'"
        />
        <budget-scope-extra
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Extra'"
        />
        <budget-scope-manutencao
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Suporte Mensal'"
        />
        <budget-scope-manutencao
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Segurança digital'"
        />
        <budget-scope-solucao-web
          :budget="budget"
          v-if="budget.budgetCategory && budget.budgetCategory.name == 'Solução Web'"
        />
        <p><strong>Essa proposta tem validade de 30 dias.</strong></p>
        <hr>
        <p class="text-right">Atenciosamente</p>
        <p class="text-right">{{ account.name }}<br>{{ account.email }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import html2pdf from 'html2pdf.js';
import PartialHeader from '@/components/budget-scope/PartialHeader.vue';
import BudgetScopeProject from '@/components/budget-scope/BudgetScopeProject.vue';
import BudgetScopeManutencao from '@/components/budget-scope/BudgetScopeManutencao.vue';
import BudgetScopePesquisa from '@/components/budget-scope/BudgetScopePesquisa.vue';
import BudgetScopeExtra from '@/components/budget-scope/BudgetScopeExtra.vue';
import moment from 'moment';
import $store from '@/store';
import BudgetScopeInMake from '../../components/budget-scope/BudgetScopeInMake.vue';
import BudgetScopeEvolucaoDigital from '../../components/budget-scope/BudgetScopeEvolucaoDigital.vue';
import BudgetScopePerformanceDigital from '../../components/budget-scope/BudgetScopePerformanceDigital.vue';
import BudgetScopeSolucaoWeb from '../../components/budget-scope/BudgetScopeSolucaoWeb.vue';

export default {
  components: {
    PartialHeader,
    BudgetScopeProject,
    BudgetScopeManutencao,
    BudgetScopePesquisa,
    BudgetScopeExtra,
    BudgetScopeInMake,
    BudgetScopeEvolucaoDigital,
    BudgetScopeSolucaoWeb,
    BudgetScopePerformanceDigital,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('viewBudgetScope', ['budget']),
    ...mapState('auth', ['account']),
  },
  beforeRouteEnter(to, from, next) {
    $store.dispatch('viewBudgetScope/getBudget', {
      id: to.params.id,
      next,
    });
  },
  methods: {
    ...mapActions('viewBudgetScope', ['upsert']),
    generatePDF() {
      const element = document.getElementById('report');
      element.style.border = 'none';
      element.innerHTML = element.innerHTML.replace(
        /;quebra;/g,
        '<div class="html2pdf__page-break"></div>'
      );
      html2pdf(element, {
        margin: 0,
        filename:
          `${moment(this.budget.updated).format('YYYY.MM')}. ` +
          `REF ${this.budget.id}. Escopo para ` +
          `${this.budget.budgetCategory.name} - ${this.budget.projectName}.pdf`,
        image: {
          type: 'jpeg',
          quality: 1,
        },
        html2canvas: {
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait',
        },
      });
      element.innerHTML = element.innerHTML.replace(
        /<div class="html2pdf__page-break"><\/div>/g,
        ';quebra;'
      );
      element.style.border = '1px solid black';
    },
    saveInnerHTML() {
      const element = document.getElementById('report');
      this.budget.scopeInnerHTML = element.innerHTML;
      this.upsert(this.budget);
    },
    deleteInnerHTML() {
      this.budget.scopeInnerHTML = null;
      this.upsert(this.budget);
    },
    goToSimulation() {
      this.$router.push({
        name: 'Simulador de orçamento',
        params: {id: this.budget.id},
      });
    },
    goToBudget() {
      this.$router.push({
        name: 'Visualizar orçamento',
        params: {id: this.budget.id},
      });
    },
    goToEdit() {
      this.$router.push({
        name: 'Criar escopo',
        params: {id: this.budget.id},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kanban /deep/ .drag-column button {
  display: none;
}
</style>
