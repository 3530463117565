<template lang="html">
  <div class="animated fadeIn">
    <div>
      <b-card-group deck>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-4">
          <b-button
            block
            variant="primary"
            @click="createBudget">
            <i class="fa fa-plus" />&nbsp; Criar proposta
          </b-button>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-4">
          <b-button
            block
            variant="primary"
            href="https://incuca.pipedrive.com/"
            target="blank">
            <i class="fa fa-hand-holding-usd" />&nbsp; Pipedrive
          </b-button>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-4">
          <b-button
            block
            variant="primary"
            href="https://analise.incuca.net"
            target="blank">
            <i class="fa fa-tachometer-alt" />&nbsp; Análises
          </b-button>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-4">
          <b-button
            block
            variant="primary"
            href="https://drive.google.com/drive/folders/0B5ZdW6rdGsKmeFlOY0RSR1Y5T0E?usp=sharing"
            target="blank">
            <i class="fa fa-star" />&nbsp; Drive Comercial
          </b-button>
        </b-card>
      </b-card-group>
      <b-card-group deck>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-2">
          <b-button
            block
            variant="primary"
            href="https://app.clicksign.com/"
            target="blank">
            <i class="fa fa-book" />&nbsp; Clicksign
          </b-button>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-2">
          <b-button
            block
            variant="primary"
            href="https://incuca.net"
            target="blank">
            <i class="fa fa-laptop" />&nbsp; Website InCuca
          </b-button>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center mb-2">
          <b-button
            block
            variant="primary"
            href="https://loja.comercial.incuca.net"
            target="blank">
            <i class="fa fa-laptop" />&nbsp; Loja virtual demo
          </b-button>
        </b-card>
      </b-card-group>
    </div>
    <!-- <div class="mt-3">
      <b-card-group deck>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center">
          <blockquote class="card-blockquote">
            <h3>Propostas</h3>
            <div class="dash-numbers">99</div>
            <footer>
              <small>Junho 2020</small>
            </footer>
          </blockquote>
        </b-card>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center">
          <blockquote class="card-blockquote">
            <h3>Potencial</h3>
            <div class="dash-numbers">R$ 99.999,99</div>
            <footer>
              <small>Junho 2020</small>
            </footer>
          </blockquote>
        </b-card>
      </b-card-group>
    </div> -->
    <div class="mt-3">
      <b-card-group deck>
        <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center">
          <h3>Negócios iniciados</h3>
          <canvas id="negocios-iniciados" />
        </b-card>
        <!-- <b-card
          bg-variant="dark"
          text-variant="white"
          class="text-center">
          <h3>Quantidade</h3>
          <p>Gráfico em barras separado por serviço</p>
        </b-card> -->
      </b-card-group>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import Chart from 'chart.js';

export default {
  computed: {
    ...mapGetters('dashboard', ['chartData', 'values', 'labels']),
  },
  watch: {
    values(values) {
      const ctx = document.getElementById('negocios-iniciados');
      this.chartData.data.datasets[0].data = values;
      this.chartData.data.labels = this.labels;
      this.chart = new Chart(ctx, {
        type: this.chartData.type,
        data: this.chartData.data,
        options: this.chartData.options,
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onRouteEnter(to);
    });
  },
  methods: {
    ...mapActions('dashboard', [
      'onRouteEnter',
      'createBudget',
    ]),
  },
};

</script>
<style scoped>
.graph {
  padding: 50px 20px;
  max-width: 1050px;
}

</style>
