export function setBudget(state, budget) {
  state.budget = budget;
}

export function updateBudget(state, budget) {
  state.budget = {...state.budget, ...budget};
}

export function removeBudget(state, budget) {
  const index = state.budgets.indexOf(budget);
  if (index !== -1) {
    state.budgets.splice(index, 1);
  }
}

export function setBudgets(state, budgets) {
  state.budgets = budgets;
}

export function getBudgetCategories(state, budgetCategories) {
  state.budgetCategories = budgetCategories;
}

export function getInstallmentsBudget(state, budgets) {
  state.budgets = budgets;
}

export function setSearchedTerms(state, searchedTerms) {
  state.searchedTerms = searchedTerms;
}
