import chartData from '@/static/templates/chart-data';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  state: {
    chartData,
    values: {},
  },
  actions,
  getters,
  mutations,
};
