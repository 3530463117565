<template>
  <div>
    <!-- eslint-disable max-len -->
    <partial-sobre-a-in-cuca />
    <partial-entendimento-das-necessidades :budget="budget" />
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>PROPOSTA TÉCNICA</strong>
        </h4>
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <p>
          Alguns desafios só podem ser superados com o uso correto da tecnologia. No
          digital, o negócio precisa operar com o máximo de performance para acompanhar os
          movimentos da internet, capturar o interesse do público, converter seguidores em
          fãs e efetivamente impulsionar o aumento da receita.
        </p>

        <p>
          Por isso, convidamos você a conhecer o serviço de Performance Digital da InCuca
          Tech! Vamos ajudá-lo a conquistar os objetivos do seu negócio e criar o ambiente
          digital perfeito para o seu público.
        </p>

        <p>
          A tecnologia será o diferencial da sua empresa e o nosso trabalho facilitará a
          tomada de decisão com base nos dados (data-driven), otimizando ao máximo a
          experiência de navegação do seu público.
        </p>

        <p>
          Nosso foco será atuar nos 20% dos problemas e desafios da sua empresa que
          atualmente consomem 80% dos esforços e investimentos, transformando os
          impedimentos em alavancas reais de crescimento. Para isso, realizaremos análises
          de dados e proposição de soluções, mantendo o seu ambiente digital com a melhor
          performance da internet e aplicando metodologias e estratégias tecnológicas de
          rápido crescimento para que o seu negócio alcance as metas propostas.
        </p>

        <p>
          Com a InCuca Tech você vai além da Transformação Digital, aqui a sua empresa
          Evolui!
        </p>

        <p style="font-size: 18px"><strong>Plano Estratégico</strong></p>
        <p style="font-size: 16px">
          <strong
            >Analise e inteligência digital (inicia no 1º mês e permanece
            contínua)</strong
          >
        </p>
        <p>
          Iniciamos com a coleta e estudo dos dados e alinhamentos estratégicos do negócio
          digital. Criando metas e marcos de acordo com a realidade digital do cliente e
          onde queremos chegar. Com essa inteligência estratégica data-driven conseguimos
          trilhar o caminho para o sucesso.
        </p>

        <ul>
          <li>Análise de infraestrutura</li>
          <ul>
            <li>Plataformas</li>
            <li>Servidor</li>
            <li>Integrações</li>
            <li>Scripts (Crons)</li>
            <li>Bancos de dados</li>
            <li>Segurança</li>
          </ul>
          <li>Mapeamento</li>
          <ul>
            <li>Canais de aquisição</li>
            <li>Fontes de tráfego</li>
            <li>Mapas de calor</li>
            <li>Jornada dos usuários</li>
            <li>Chamadas para ação</li>
          </ul>
        </ul>
        <div class="html2pdf__page-break"></div>
        <ul class="mt-5">
          <li>Análise de dados</li>
          <ul>
            <li>Audiência</li>
            <li>Rejeição</li>
            <li>Health score</li>
            <li>Benchmarking</li>
            <li>SEO</li>
          </ul>
          <li>Objetivos estratégicos do negócio digital</li>
          <ul>
            <li>North Star Metric</li>
            <li>Maturidade digital</li>
            <li>Estratégias</li>
            <li>Metas</li>
            <li>Métricas (KPIs)</li>
            <li>Backlog</li>
          </ul>
        </ul>

        <p style="font-size: 15px">
          <strong>Resultado</strong>: entender o real problema do seu negócio e como ele
          está posicionado diante dos principais pilares que garantem uma boa performance
          digital: Plataforma otimizada, SEO, Conversão, Usabilidade, Segurança e
          Integrações com outros sistemas, analisando os dados já coletados se existirem
          ou iniciando a configuração da coleta de dados. Este trabalho nos direciona para
          tirar o melhor proveito dos insights e oportunidades identificadas no andamento
          do trabalho e assim potencializar a presença digital e resultados do seu
          negócio.
        </p>
        <br />
        <p style="font-size: 16px">
          <strong>Otimização digital (inicia no 2º mês e permanece contínua)</strong>
        </p>

        <p>
          Manter a performance do ambiente digital; mudar estruturas e agregar mais
          tecnologia à plataforma; aplicar técnicas de UX/UI Design com base nas
          características dos usuários e acompanhar as mudanças dos algoritmos de busca
          (como o Google).
        </p>

        <ul>
          <li>Otimização da plataforma</li>
          <ul>
            <li>Velocidade de carregamento</li>
            <li>Cache de plataforma</li>
            <li>Otimização de imagens</li>
            <li>Minificação de código</li>
            <li>Links quebrados</li>
            <li>Hierarquia de títulos</li>
            <li>Códigos para otimizar leitura de algoritmos de busca</li>
          </ul>
          <li>Otimização de servidor</li>
          <ul>
            <li>Cache de servidor</li>
            <li>Memória Swap</li>
            <li>CDN</li>
          </ul>
        </ul>

        <p style="font-size: 15px">
          <strong>Resultado</strong>: o seu ambiente digital terá como meta alcançar nota
          acima de 90 nos medidores de performance do Google, o que impulsiona direta e
          positivamente as taxas de conversão, audiência e posicionamento.
        </p>
        <div class="html2pdf__page-break"></div>
        <p style="font-size: 16px" class="mt-5">
          <strong>Otimizar Audiência (inicia no 3º mês e permanece contínua)</strong>
        </p>
        <p>
          Chegou a hora de qualificar a sua audiência, queremos que as pessoas certas
          visitem o seu ambiente digital, pois elas serão as que mais irão se beneficiar
          com o conteúdo que você está entregando, além de impulsionarem de forma orgânica
          e sustentável o seu crescimento na web.
        </p>

        <ul>
          <li>Público-alvo</li>
          <ul>
            <li>Perfil</li>
            <li>Proto-persona</li>
            <li>Audiência</li>
          </ul>
          <li>Jornada do usuário</li>
          <ul>
            <li>Fontes de tráfego</li>
            <li>Fontes de tráfego</li>
          </ul>
          <li>Usabilidade</li>
          <ul>
            <li>Fluxo do usuário</li>
            <li>UX/UI como fontes de melhoria de layout</li>
          </ul>
          <li>Teste A/B</li>
          <ul>
            <li>Multivariável</li>
            <li>Alterações de layout</li>
          </ul>
          <li>SEM (Search Engine Marketing)</li>
          <ul>
            <li>SEO (Search Engine Optimization)</li>
            <ul>
              <li>Mapeamento de palavras-chave</li>
              <li>Link building</li>
            </ul>
            <li>Conteúdo digital* (estratégia e análise)</li>
            <li>Social Ads* (estratégia e análise)</li>
            <li>Social mídia* (estratégia e análise)</li>
            <li>Branding* (estratégia e análise)</li>
          </ul>
        </ul>

        <p style="font-size: 15px">
          <strong>Resultado</strong>: pleno conhecimento da audiência e garantia da
          otimização do ambiente digital para que este esteja funcionando de acordo com as
          melhores práticas de SEO e Usabilidade. O trabalho de SEO será sempre focado no
          caminho que o usuário percorre até o momento em que ele manifesta interesse na
          aquisição do seu serviço e/ou produto.
        </p>
        <br />
        <p style="font-size: 16px">
          <strong>Otimizar Conversão (inicia no 4º mês e permanece contínua)</strong>
        </p>
        <p>
          Um bom projeto de SEO é aquele capaz de criar estratégias e tomar ações que
          visam garantir que as visitas tenham potencial para se transformar em novas
          oportunidades de negócio. Queremos que os seus visitantes qualificados convertam
          e que com o uso correto da tecnologia, eles se tornem fãs do seu negócio. E o
          melhor de tudo: as decisões sempre serão facilitadas pelos dados e pelas novas
          descobertas.
        </p>

        <ul>
          <li>CRO (Conversion Rate Optimization)</li>
          <ul>
            <li>Landing pages</li>
            <li>Copywriting</li>
            <li>Mapa de calor</li>
            <li>Remarketing</li>
            <li>Email-marketing* (estratégia e análise)</li>
            <li>Testes A/B para as principais páginas de acesso e de captação</li>
          </ul>
        </ul>
        <div class="html2pdf__page-break"></div>
        <p style="font-size: 15px" class="mt-5">
          <strong>Resultado</strong>: garantir que as visitas via tráfego orgânico, dentre
          outras fontes de origem, transformem-se em novas oportunidades de negócio. O
          estudo para combinar as estratégias de SEO com a geração de oportunidades
          baseia-se numa análise dos dados de audiência, tráfego, fluxo de navegação, taxa
          de rejeição, taxa de conversão e número de leads atuais do website e projeções
          futuras. Também serão implementados testes A/B para as principais páginas de
          acesso e de captação, no sentido de validar fluxo de informação, fluxo de
          navegação, títulos e headlines, design (cores, tipografia), layout e imagens.
        </p>
        <br />
        <p style="font-size: 16px">
          <strong>Otimizar Receita (inicia no 5º mês e permanece contínua)</strong>
        </p>
        <p>
          Vamos encontrar e ajustar as alavancas de crescimento de receita do seu ambiente
          digital. Quando chegar a esse estágio, tudo o que mais gera resultado em um
          ambiente digital já estará em prática, mas é importante não perder o ritmo e,
          principalmente, não deixar dinheiro na mesa. Show me the money!
        </p>

        <ul>
          <li>Gatilhos mentais</li>
          <ul>
            <li>Chamadas de vendas</li>
            <li>Frases persuasivas</li>
          </ul>
        </ul>

        <p style="font-size: 15px">
          <strong>Resultado</strong>: ambiente sempre atualizado com as melhores práticas
          de SEO, Copywriting e Conversão, garantindo a obtenção dos resultados esperados
          de acordo com as metas estabelecidas para o projeto do cliente e avançando
          sempre em busca de mais por meio da consultoria mensal com a InCuca Tech
        </p>
        <br />
        <p style="font-size: 18px">
          <strong
            >Revisão semestral de Performance Digital e avaliação da Transformação Digital
            na empresa</strong
          >
        </p>

        <p>
          Ao chegar nesse ponto nós já teremos alavancado completamente a sua performance
          digital. Mas como estamos falando até aqui sobre o apoio tecnológico e
          estratégico focado no Marketing e nas Vendas do seu negócio, não podemos deixar
          todo o restante da estrutura corporativa carecer de atualizações e otimizações
          regulares.
        </p>

        <p>
          Uma vez que os resultados de Marketing aquecem a Máquina de Vendas e que toda a
          operação precisa correr para atender essa demanda, faz-se necessário automatizar
          o máximo de processos possíveis para o negócio ganhar escala e efetivamente
          crescer. E para não criar gargalos, a tecnologia mais uma vez poderá ser
          acionada, superando os novos desafios por meio da automatização de fluxos de
          trabalho.
        </p>

        <p>
          Nesse momento existirá uma real necessidade de revisão tecnológica em todo o
          negócio para nivelar a aplicação da tecnologia em outras áreas ou setores da
          empresa. E o mais importante: a InCuca seguirá com você! Avaliando o nível de
          transformação digital e impulsionando as novas alavancas de crescimento.
        </p>
        ;quebra;
        <h4 class="pt-5 exo2 text-center website">
          <strong>METODOLOGIA</strong>
        </h4>

        <p>
          Para o desenvolvimento do trabalho usamos de diversas metodologias, e vamos
          encaixando a melhor que se enquadre na necessidade do cliente. <br />
          O consumo das horas propostas envolvem design, programação e fluxo de
          atendimento como um todo.<br />
          A produção irá debitar do banco de horas do cliente todo e qualquer tipo de
          investimento para que o problema, dor ou necessidade do cliente seja resolvido,
          desde uma reunião de alinhamento, análise de novas solicitações, programação,
          avaliação de ambientes, design, etc.
        </p>

        <p style="text-decoration: underline">
          Itens a serem considerados que serão debitados das horas propostas:
        </p>

        <p>
          - Planejamento e gestão do backlog do cliente/produto<br />
          - Pesquisa e análise oriundas de solicitações do cliente<br />
          - Desenvolvimento (Design, Programação, Testes, Treinamento)<br />
          - Reuniões/Comunicação/Atendimento ao cliente<br />
          - Manutenção do Ambiente de Homologação<br />
          - Manutenção do Ambiente de Produção<br />
          - Correção e avaliação de Bugs<br />
          - Documentação imposta pelo contrato ou solicitada pelo cliente
        </p>

        <p>
          <strong
            >O cronograma deve ser estabelecido pelo Gerente de Projetos da
            InCuca.</strong
          ><br />
          A execução do trabalho se dá remotamente. Nas etapas que envolvem reuniões, as
          mesmas ocorrem online (Google meeting ou outras ferramentas) e têm duração de 1
          hora. <br />
          Se o cliente requerer atendimento InCompany é cobrado a parte (por hora) após o
          atendimento (contando a hora de saída InCuca até a hora de retorno).
        </p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-4">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>PROPOSTA COMERCIAL</strong>
        </h4>
        <p>
          A contratação do serviço se dá através do regime de banco de horas. O indicado
          para viabilizar o trabalho é de
          <strong>{{ budget.projectDescriptionValue }} horas/mês</strong>.
        </p>
        <p style="text-decoration: underline">
          <strong>Investimento mensal: {{ totalPrice }}</strong>
        </p>
        <p>Forma de pagamento: Boleto bancário</p>
        <p>Vencimento: Dia 01 de cada mês</p>
        <p>
          Não há fidelidade. Solicitações de cancelamento devem ser feitas com 30 dias de
          antecedência e as cobranças nesse ínterim devem ser quitadas.
        </p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center ecommerce">
          <strong>IMPORTANTE</strong>
        </h4>
        <p>
          Essa proposta não contempla produção de conteúdo, gestão de mídias pagas e
          gestão de redes sociais. Nesse caso, podemos contar com os nossos parceiros e
          indicá-los na execução dessas atividades.
        </p>
        <p>
          Saiba mais sobre evolução digital:
          <a href="https://incuca.net/evolucao-digital-como-e-esse-servico-da-incuca/"
            >https://incuca.net/evolucao-digital-como-e-esse-servico-da-incuca/.</a
          >
        </p>
        <p>
          Estando de acordo com as condições desta proposta, precisaremos dos seguintes
          dados para emissão de nota fiscal:
        </p>
        <p>
          CNPJ, Razão Social, Endereço, Nome completo do representante legal e CPF do
          mesmo.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PartialEntendimentoDasNecessidades from "./PartialEntendimentoDasNecessidades.vue";
import PartialSobreAInCuca from "./PartialSobreAInCuca.vue";

export default {
  components: { PartialEntendimentoDasNecessidades, PartialSobreAInCuca },
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    totalPrice() {
      return this.budget.valueBudget.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
};
</script>

<style></style>
