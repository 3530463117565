<template>
  <b-row
    v-if="budget.projectDescription"
    class="pt-5"
  >
    <b-col>
      <h4 class="exo2 text-center website">
        <strong>ENTENDIMENTO DAS NECESSIDADES</strong>
      </h4>
      <span
        class="text"
        v-html="budget.projectDescription"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>
