<template lang="html">
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <!-- eslint-disable -->
        <p>DESENVOLVIMENTO DE PROJETO: <a
          href="https://docs.google.com/document/d/1xpxI03DT53WZuPQ-9ZE3bLtedo5LT_t238tsPvyavNU"
          target="_blank">link para download</a></p>
        <p>SEGURANÇA DIGITAL DE PROJETO: <a
          href="https://docs.google.com/document/d/1RB7v72_3TPRDxAywtNtboANDrn08k5Opyj3QH34CrJs"
          target="_blank">link para download</a></p>
        <p>ACORDO DE NÃO DIVULGAÇÃO - NDA: <a
          href="https://docs.google.com/document/d/1E83YDWBmRRbffmJ6p6Syp0XA90apL5KQGXDWgOoq6CU"
          target="_blank">link para download</a></p>
        <p>BANCO DE HORAS MENSAIS (VIP): <a
          href="https://docs.google.com/document/d/1JOLZ_2NGhH9ivrZujOynB7g0u844074pI1kSgQUE4Ds"
          target="_blank">link para download</a></p>
          <p>TERMO DE COOPERAÇÃO: <a
          href="https://drive.google.com/file/d/174hwElyTF8giCc5EARBQrxqhgswyayrG/view"
          target="_blank">link para download</a></p>
          <p>BANCO DE HORAS: <a
          href="https://drive.google.com/file/d/0B5VMuC5_eSFSSnN0M2NOSGhjbkFSanB1TVNSRWVBSEd6Mk1n/view"
          target="_blank">link para download</a></p>
        <p>Administração de contratos e assinaturas: <a
          href="https://app.clicksign.com/"
          target="_blank">Clicksign</a></p>

          <!-- eslint-enable -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>
