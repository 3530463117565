import chartData from '@/static/templates/chart-data';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default {
  namespaced: true,
  state: {
    persons: [],
    deals: [],
    dealFields: [],
    results: [],
    values: {
      173: 0,
      174: 0,
      175: 0,
      176: 0,
      177: 0,
      178: 0,
      179: 0,
      180: 0,
      181: 0,
      182: 0,
      183: 0,
      184: 0,
    },
    chartData,
    teste: 10,
  },
  getters,
  actions,
  mutations,
};
