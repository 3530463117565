import lb from '@/services/loopback';

export default {
  namespaced: true,
  actions: {
    getPlugins() {
      return lb
        .find('/Plugins');
    },

    getPlugin(_, id) {
      return lb
        .find(`/Plugins/${id}`);
    },

    upsertPlugin(_, plugin) {
      const updatedPlugin = plugin;
      if (updatedPlugin.id) {
        const id = updatedPlugin.id;
        return lb.patch(`/Plugins/${id}`, updatedPlugin);
      }
      return lb
        .post('/Plugins', updatedPlugin);
    },

    removePlugin(_, plugin) {
      return lb
        .delete(`/Plugins/${plugin.id}`);
    },

    search(_, term) {
      return lb.find('/Plugins', {
        where: {name: {like: `%${term}%`}},
      });
    },

  },
};

