<template>
  <div>
    <partial-sobre-a-in-cuca />
    <b-row class="pt-5">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>PROPOSTA TÉCNICA e COMERCIAL</strong>
        </h4>
        <p>O serviço de Segurança Digital da InCuca envolve ações de monitoramento, segurança e suporte mensal para o bom funcionamento do ambiente digital do cliente, restrito a uma URL por contrato definida no momento da contratação.</p>
        <p>A prioridade do serviço de Manutenção da InCuca é o bom funcionamento da plataforma digital. Para isso, disponibiliza programadores experientes para a análise de problemas, segurança contra ataques e invasões e sugestões de melhorias quando necessárias. As ações envolvem tanto a aplicação quanto o servidor. Utilizando boas práticas de programação os ajustes irão contribuir para a escalada do ambiente digital nos principais buscadores (Google, Bing, Yahoo).</p>
        <p>O serviço de Manutenção da InCuca é perfeito para quem quer focar no desenvolvimento do negócio e dos conteúdos na internet, tendo certeza do bom funcionamento da plataforma digital.</p>
        <p>Importante ressaltar que o objetivo é manter e otimizar o ambiente que já existe. Para isso o editor web precisará informar com antecedência sempre que desejar realizar alguma ação técnica na plataforma, para que a InCuca possa preparar e orientar sobre possíveis boas práticas. Não estão inclusas o desenvolvimento de novas funcionalidades. Estas devem ser orçadas individualmente e contam com a consultoria da InCuca.</p>

      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>COBERTURA DO SERVIÇO</strong>
        </h4>
        <p style="font-size: 16px"><strong>Alinhamentos e Relatórios</strong></p>
        <p>
          <ul>
            <li>Alinhamentos de objetivos do ambiente digital;</li>
            <li>Monitoramento de quedas, vulnerabilidades, desempenho, SEO e links quebrados;</li>
            <li>Relatório mensal sobre desempenho da aplicação e servidor;</li>
            <li>Análise de problemas e propostas para correção;</li>
            <li>Explicações pontuais sobre ajustes e implementações realizadas;</li>
            <li>Tratamento de memória cache para melhor velocidade de carregamento;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>Análises de problemas</strong></p>
        <p>
          <ul>
            <li>Análises de problemas e proposta para correção;</li>
            <li>Correção de erros legados e de terceiros NÃO estão incluso na Manutenção. Caso sejam erros gerados pela InCuca serão absorvidos pelo contrato de Manutenção;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>Manter o ambiente atualizado</strong></p>
        <p>
          <ul>
            <li>Atualização periódica de plugins para manter a segurança e a evolução do ambiente. Dependendo da compatibilidade entre versões de plugins, tema e plataforma para evitar conflitos;</li>
            <li>Se WordPress, chaves e acessos a plugins devem ser fornecidos pelo cliente, principalmente para plugins pagos;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>Segurança</strong></p>
        <p>
          <ul>
            <li>Backup a cada 15 dias, arquivando as três últimas versões;</li>
            <li>Se WordPress, configuração do plugin de segurança WordFence para garantir a máxima segurança ao ambiente e ativar o Akismet contra spam;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>SSL</strong></p>
        <p>
          <ul>
            <li>Atualização a cada 3 meses do certificado de segurança para garantir perante ao Google e outros buscadores a legitimidade do ambiente utilizando Let's Encrypt;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>Ajustes no servidor</strong></p>
        <p>
          <ul>
            <li>Ajustes de segurança e recursos para o servidor rodar o ambiente sem prejudicar o desempenho;</li>
          </ul>
        </p>
        <p style="font-size: 16px"><strong>Tempo de carregamento</strong></p>
        <p>
          <ul>
            <li>Tratamento de memória cache para melhor velocidade de carregamento;</li>
          </ul>
        </p>
        <p>No início do serviço é cobrada uma <strong>Taxa de ativação</strong> é referente a configuração inicial de robôs de monitoramento e análises iniciais.</p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>ACORDO DE NÍVEL DE SERVIÇO PARA ATENDIMENTO</strong>
        </h4>
        <p>Em até 12 horas caso o problema/dúvida/solicitação impede a empresa/usuário de realizar processos básicos de faturamento/pagamento.</p>
        <p>Em até 2 dias caso o problema/dúvida/solicitação não impede a empresa/usuário de realizar processos básicos mas causa retardo na execução das tarefas.</p>
        <p>Em até 5 dias caso o problema/dúvida/solicitação impede a empresa/usuário de realizar processos secundários.</p>
        <p>Em até 10 dias em caso de outra solicitação que não se encaixe em nenhuma das anteriores.</p>
        <p><strong>Demandas NÃO inclusas que estão disponíveis para contratações extras.</strong></p>
        <p>
          <ul>
            <li>Os investimentos necessários para a compra de licenças de plugins são de responsabilidade do Cliente, que deverá adiantar os valores/aquisição.</li>
            <li>Ajustes em páginas do ambiente, necessário contabilizar quantas páginas serão e quais os ajustes necessários.</li>
            <li>Configuração e inserção de códigos de rastreio como Pixel do Facebook, Tracking Google Analytics.</li>
            <li>Para ambientes que usam o plugin do Eventon configurar eventos para os clientes.</li>
            <li>Verificar pontos de melhorias para SEO nas páginas do ambiente e enviar recomendações para o editor web.</li>
            <li>Configurar e manter organizadas as entradas de DNS.</li>
            <li>Acompanhar e prestar suporte para integrações e o bom funcionamento dessas.</li>
            <li>Customizações de layout ou programação são orçadas por hora, podendo o contrato prever um pacote de horas não cumulativas mensais para o projeto.</li>
          </ul>
        </p>
        <p>Para o suporte da InCuca em demandas extras ou para o desenvolvimento de novas funcionalidades o valor da hora/desenvolvimento é R$ {{ EVOLUCAO }}/hora.</p>
        <p><strong>Informações adicionais</strong></p>
        <p>A contratação de horas de desenvolvimento para clientes que não possuem o contrato de Segurança Digital é R$ 220/hora; Não há um número fixo de horas no serviço, porém cabe ao Maker da InCuca a análise e definição dos prazos de implementação; Serviço mensal com duração cíclica de 12 meses; Serviços não descritos nesta Proposta, serão cobrados como extras.</p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>PROPOSTA COMERCIAL</strong>
        </h4>
        <p>A contratação do serviço se dá através da taxa de ativação e mensalidade. </p>
        <p style="text-decoration:underline"><strong>Taxa de ativação: R$ 420,00 referente a configurações iniciais de monitoramento.</strong></p>
        <p style="text-decoration:underline"><strong>Mensalidade CMS: R$ {{ MANUTENCAO }},00 referente ao serviço mensal.</strong></p>
        <p>Forma de pagamento: Boleto bancário</p>
        <p>Vencimento: Dia 01 de cada mês</p>
        <p>Não há fidelidade. Solicitações de cancelamento devem ser feitas com 30 dias de antecedência e as cobranças nesse ínterim devem ser quitadas.</p>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>CONTRATAÇÃO e CONDIÇÕES DE PAGAMENTO</strong>
        </h4>
        <p>A contratação é online, tendo o contrato assinado de forma digital.</p>

        <p>O pagamento que corresponde ao projeto em si, pode ser parcelado em até 3 vezes (ato, 30 e 60 dias).</p>
        <p>Já o pagamento que corresponde à mensalidade é cobrado mediante boleto bancário com data de vencimento combinada com o cliente e de forma pré-paga.</p>

        <p>Estando de acordo com as condições desta proposta, precisaremos dos seguintes dados para emissão de nota fiscal:<br>
          CNPJ, Razão Social, Endereço, Nome completo do representante legal e CPF do mesmo. </p>

        <p class="mb-0"><strong>Outras solicitações não descritas nesse escopo deverão ser orçadas a parte.</strong></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {EVOLUCAO, MANUTENCAO} from '@/constants';
import PartialSobreAInCuca from './PartialSobreAInCuca.vue';

export default {
  components: {PartialSobreAInCuca},
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      EVOLUCAO,
      MANUTENCAO,
    };
  },
  computed: {
    totalPrice() {
      return this.budget.valueBudget.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>

<style>
</style>
