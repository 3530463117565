const data = {
  labels: [],
  datasets: [{
    label: 'Serviços de interesse',
    backgroundColor: [
      '#d6ecff',
      '#ffff00',
      '#4caf50',
      '#2196f3',
      '#f44336',
      '#ab47bc',
      '#fbc02d',
      '#ff9800',
      '#333333',
      '#5c6bc0',
      '#2d43c4',
      '#417ead',
    ],
    data: [],
  }],
};
const options = {
  title: {
    display: true,
    text: 'Negócios iniciados: porcentagem por serviços',
  },
};
export const planetChartData = {
  type: 'doughnut',
  data,
  options,
};

export default planetChartData;
