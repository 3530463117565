<template>
  <div>
    <!-- eslint-disable max-len -->
    <b-row class="mb-4">
      <b-col>
        <b-button
          variant="primary"
          @click="goToBudgetScope">Escopo</b-button>
        <b-button
          variant="primary"
          @click="generatePDF">Download em PDF</b-button>
        <b-button
          variant="primary"
          @click="saveInnerHTML">Salvar</b-button>
        <b-button
          variant="primary"
          @click="deleteInnerHTML">Desfazer</b-button>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>Utilizar ;quebra; para quebrar páginas</b-col>
    </b-row>
    <div
      v-if="budget.budgetInnerHTML"
      id="report"
      contenteditable="true"
      class="bg-white p-5 mb-5"
      style="max-width: 8in;font-family:'Roboto'"
      v-html="budget.budgetInnerHTML"/>
    <div
      v-else
      id="report"
      contenteditable="true"
      class="bg-white p-5 mb-5"
      style="max-width: 8in;font-family:'Roboto'">
      <b-container class="px-0">
        <b-row class="mb-5">
          <b-col
            md="12"
            class="text-center position-relative"
            style="margin-bottom: 150px">
            <img
              src="/static/img/incuca-pb.png"
              class="w-50">
          </b-col>
          <b-col
            md="12"
            class="text-center position-relative mb-5">
            <img
              src="/static/img/incuca-marca.png"
              style="max-width: 400px;margin-bottom:50px">
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            class="text-center mb-4">
            <h1 class="mb-0">
              <strong class="exo2">
                <span
                  v-if="budget.budgetCategory && budget.budgetCategory.name"
                  class="website text-uppercase">{{ budget.budgetCategory.name }}</span>
              </strong>
            </h1>
            <h4 class="text-gray exo2">ORÇAMENTO</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <h2 class="mb-0 exo2">
              <strong>REF {{ budget.id }}</strong>
            </h2>
            <h5 class="mb-4 exo2">{{ budget.projectName }} - {{ budget.client.name }}</h5>
            <h6 class="exo2">
              <strong>Florianópolis, {{ currentDate }}</strong>
            </h6>
          </b-col>
        </b-row>
        <div
          v-if="budget.budgetCategory.name === 'Suporte Mensal'"
          class="html2pdf__page-break"/>
        <div
          v-if="budget.budgetCategory.name === 'Segurança Digital'"
          class="html2pdf__page-break"/>
        <b-row
          v-if="budget.budgetCategory.name === 'Suporte Mensal' || budget.budgetCategory.name === 'Segurança Digital'"
          class="mt-4 mb-4">
          <b-col>
            <h2 class="exo2">Serviços Contratados</h2>
            <h5 class="text-gray">Serviço de acompanhamento mensal do ambiente digital.</h5>
            <p class="text-gray">
              O serviço de Segurança Digital da InCuca envolve ações de monitoramento, segurança e suporte mensal para o bom funcionamento do ambiente digital do cliente, restrito a uma URL por contrato definida no momento da contratação.
            </p>
            <p class="text-gray">
              A prioridade do serviço de Segurança Digital da InCuca é o bom funcionamento da plataforma digital. Para isso, disponibiliza programadores experientes para a análise de problemas, segurança contra ataques e invasões e sugestões de melhorias quando necessárias. As ações envolvem tanto a aplicação quanto o servidor. Utilizando boas práticas de programação os ajustes irão contribuir para a escalada do ambiente digital nos principais buscadores (Google, Bing, Yahoo).
            </p>
            <p class="text-gray">
              O serviço de Segurança Digital da InCuca é perfeito para quem quer focar no desenvolvimento do negócio e dos conteúdos na internet, tendo certeza do bom funcionamento da plataforma digital.
            </p>
            <p class="text-gray">
              Importante ressaltar que o objetivo é manter e otimizar o ambiente que já existe. Para isso o editor web precisará informar com antecedência sempre que desejar realizar alguma ação técnica na plataforma, para que a InCuca possa preparar e orientar sobre possíveis boas práticas. Não estão inclusas o desenvolvimento de novas funcionalidades. Estas devem ser orçadas individualmente e contam com a consultoria da InCuca.
            </p>
            <p class="text-gray">
              <strong>Alinhamentos e Relatórios</strong>
              <ul>
                <li>Alinhamentos de objetivos do ambiente digital;</li>
                <li>Monitormanto de quedas, vulnerabilidades, desempenho, SEO e links quebrados;</li>
                <li>Relatório mensal sobre desempenho da aplicação e servidor;</li>
                <li>Análise de problemas e propostas para correção;</li>
                <li>Explicações pontuais sobre ajustes e implementações realizadas;</li>
                <li>Tratamento de memória cache para melhor velocidade de carregamento;</li>
              </ul>
              <strong>Análises de problemas</strong>
              <ul>
                <li>Análises de problemas e proposta para correção;</li>
                <li>Correção de erros legados e de terceiros NÃO estão incluso na Manutenção. Caso sejam erros gerados pela InCuca serão absorvidos pelo contrato de Manutenção;</li>
              </ul>
              <strong>Manter o ambiente atualizado</strong>
              <ul>
                <li>Atualização periódica de plugins para manter a segurança e a evolução do ambiente. Dependendo da compatibilidade entre versões de plugins, tema e plataforma para evitar conflitos;</li>
                <li>Se WordPress, chaves e acessos a plugins devem ser fornecidos pelo cliente, principalmente para plugins pagos;</li>
              </ul>
              <strong>Segurança</strong>
              <ul>
                <li>Backup a cada 15 dias, arquivando as três últimas versões;</li>
                <li>Se WordPress, configuração do plugin de segurança WordFence para garantir a máxima segurança ao ambiente e ativar o Akismet contra spam;</li>
              </ul>
              <strong>SSL</strong>
              <ul>
                <li>Atualização a cada 3 meses do certificado de segurança para garantir perante ao Google e outros buscadores a legitimidade do ambiente utilizando Let's Encrypt;</li>
              </ul>
              <strong>Ajustes no servidor</strong>
              <ul>
                <li>Ajustes de segurança e recursos para o servidor rodar o ambiente sem prejudicar o desempenho;</li>
              </ul>
              <strong>Tempo de carregamento</strong>
              <ul>
                <li>Tratamento de memória cache para melhor velocidade de carregamento;</li>
              </ul>
            </p>
            <p class="text-gray">
              <strong>Acordo de Nível de Serviço para o atendimento</strong>
              <br >
              Em até 12 horas caso o problema/dúvida/solicitação impede a empresa/usuário de realizar processos básicos de faturamento/pagamento.
              <br>
              Em até 2 dias caso o problema/dúvida/solicitação não impede a empresa/usuário de realizar processos básicos mas causa retardo na execução das tarefas.
              <br>
              Em até 5 dias caso o problema/dúvida/solicitação impede a empresa/usuário de realizar processos secundários.
              <br>
              Em até 10 dias em caso de outra solicitação que não se encaixe em nenhuma das anteriores.
            </p>
            <p class="text-gray">
              <strong>Demandas NÃO inclussas porém disponíveis para contratações extras</strong>
              <br >
              Os investimentos necessários para a compra de licenças de plugins são de responsabilidade do Cliente, que deverá adiantar os valores/aquisição.
              <br>
              Ajustes em páginas do ambiente, necessário contabilizar quantas páginas serão e quais os ajustes necessários.
              <br>
              Configuração e inserção de códigos de rastreio como Pixel do Facebook, Tracking Google Analytics.
              <br>
              Para ambientes que usam o plugin do Eventon configurar eventos para os clientes.
              <br>
              Verificar pontos de melhorias para SEO nas páginas do ambiente e enviar recomendações para o editor web.
              <br>
              Configurar e manter organizadas as entradas de DNS.
              <br>
              Acompanhar e prestar suporte para integrações e o bom funcionamento dessas.
              <br>
              Customizações de layout ou programação são orçadas por hora, podendo o contrato prever um pacote de horas não cumulativas mensais para o projeto.
              <br>
              Para o suporte da InCuca em demandas extras ou para o desenvolvimento de novas funcionalidades o valor da hora/desenvolvimento é R$ {{ EXTRA }}/hora.
            </p>
            <p class="text-gray">
              <strong>Informações adicionais</strong>
              <br >
              A contratação de horas de desenvolvimento para clientes que não possuem o contrato de Suporte Mensal é R$ {{ EXTRA }}/hora; <br >
              Não há um número fixo de horas no serviço, porém cabe ao Maker da InCuca a análise e definição dos prazos de implementação; <br >
              Serviço mensal com duração cíclica de 12 meses; <br >
              Serviços não descritos nesta Proposta, serão cobrados como extras.
            </p>
          </b-col>
        </b-row>
        <div class="html2pdf__page-break"/>
        <b-row class="mt-5 mb-4">
          <b-col>
            <h2 class="exo2">Investimento</h2>
            <h5 class="text-gray">{{ budget.budgetCategory.name }}</h5>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h5 class="mb-0 exo2">
              <strong v-if="(budget.budgetCategory.name !== 'Suporte Mensal' && budget.budgetCategory.name !== 'Segurança Digital') && budget.selected == 'isCash'">À vista</strong>
              <strong v-if="(budget.budgetCategory.name === 'Suporte Mensal' || budget.budgetCategory.name === 'Segurança Digital' ) || budget.selected == 'isMonthly'">Mensal</strong>
            </h5>
            <h1 class="exo2">
              <strong>
                {{ totalPrice }}
              </strong>
            </h1>
            <h5
              class="text-gray"
              v-html="installmentText"/>
            <span
              v-if="budget.budgetCategory && budget.budgetCategory.name == 'InMake'"
              class="exo2">
              + R$ {{ INMAKE_MENSAL }},00/mês para manter a plataforma, incluso suporte e hospedagem
              (valor a ser cobrado a partir da finalização do projeto)</span>
          </b-col>
          <b-col v-if="budget.budgetCategory.name !== 'Suporte Mensal' && budget.budgetCategory.name !== 'Segurança Digital'">
            <p class="mb-0 exo2">
              <strong>Observação</strong>
            </p>
            <p class="text-gray font-size-small">Não incluso valores de serviços adicionais ou extras que por ventura possam ser necessários durante o projeto. Para esses serviços será considerado o valor hora de R$ {{ EXTRA }},00. Este valor contempla impostos.
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h4 class="exo2">
              <strong>Instruções para contratação</strong>
            </h4>
            <p class="mb-0 text-gray">
              Estando de acordo com os itens da proposta, pedimos que envie os dados cadastrais da empresa para o email <a href="comercial@incuca.com.br">comercial@incuca.com.br</a>, para elaboração do contrato. Dados necessários para elaboara contrato digital:
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <p class="mb-0">
              <strong>- Nome completo/Razão Social:</strong>
            </p>
            <p class="mb-0">
              <strong>- Email de quem assina como parte:</strong>
            </p>
            <p class="mb-0">
              <strong>- Email de quem assina como testemunha:</strong>
            </p>
            <p class="mb-0">
              <strong>- CPF/CNPJ:</strong>
            </p>
            <p class="mb-0">
              <strong>- Endereço:</strong>
            </p>
            <p class="mb-0">
              <strong>- Forma de pagamento: mensal</strong>
            </p>
            <p class="mb-0">
              <strong>- E-mail para envio dos boletos e nota fiscal:</strong>
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col class="text-gray">
            <p class="mb-1">Esta proposta de orçamento é válida por 30 dias.</p>
            <p class="mb-1">Após a confirmação do 1º pagamento, entraremos em contato para o
            <strong>início do seu projeto</strong>.</p>
            <p class="mb-1">Cordialmente,</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import $store from '@/store';
import {EXTRA, INMAKE_MENSAL} from '@/constants';

export default {
  data() {
    return {
      EXTRA,
      INMAKE_MENSAL,
    };
  },
  computed: {
    ...mapState('viewBudget', ['budget']),
    currentDate() {
      return moment(this.budget.updated).format('DD/MM/YYYY');
    },
    totalPrice() {
      return this.budget.valueBudget.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    installmentPrice() {
      if (
        this.budget &&
        this.budget.installments &&
        this.budget.installments[0]
      ) {
        return this.budget.installments[0].value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      }
      return 0;
    },
    installmentText() {
      if (
        this.budget &&
        this.budget.installments &&
        this.budget.installments.length !== 0
      ) {
        return `Ou ${this.budget.installments.length} parcelas de ${
          this.installmentPrice
        }`;
      }
      return '';
    },
  },
  beforeRouteEnter(to, from, next) {
    $store.dispatch('viewBudget/getBudget', {
      id: to.params.id,
      next,
    });
  },
  methods: {
    ...mapActions('viewBudget', ['upsert']),
    generatePDF() {
      const element = document.getElementById('report');
      if (this.budget && this.budget.budgetCategory) {
        element.innerHTML = element.innerHTML.replace(/;quebra;/g,
          '<div class="html2pdf__page-break"></div>');
        html2pdf(element, {
          margin: 0,
          filename: `${moment(this.budget.updated).format('YYYY.MM')}. REF ${
            this.budget.id
          }. Orçamento para ${this.budget.budgetCategory.name} - ${
            this.budget.projectName
          }.pdf`,
          image: {
            type: 'jpeg',
            quality: 1,
          },
          html2canvas: {
            dpi: 192,
            letterRendering: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: 'portrait',
          },
        });
        element.innerHTML = element.innerHTML
          .replace(/<div class="html2pdf__page-break"><\/div>/g, ';quebra;');
      }
    },
    saveInnerHTML() {
      const element = document.getElementById('report');
      this.budget.budgetInnerHTML = element.innerHTML;
      this.upsert(this.budget);
    },
    deleteInnerHTML() {
      this.budget.budgetInnerHTML = null;
      this.upsert(this.budget);
    },
    goToBudgetScope() {
      this.$router.push({
        name: 'Visualizar escopo',
        params: {id: this.budget.id},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kanban /deep/ .drag-column {
  max-width: 150px;
}
</style>
