<template>
  <div class="drag-container">
    <ul class="drag-list">
      <li
        v-for="stage in stages"
        :class="{['drag-column-' + stage]: true}"
        :key="stage"
        class="drag-column">
        <button
          type="button"
          class="close float-right text-white"
          aria-label="Close"
          @click="removeStage(stage)">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="drag-column-header">
          <h2>{{ stage }}</h2>
        </span>
        <div class="drag-options"/>
        <ul
          ref="list"
          :data-status="stage"
          class="drag-inner-list">
          <li
            v-for="block in getBlocks(stage)"
            :data-block-id="block.id"
            :key="block.id"
            class="drag-item">
            <slot :name="block.id">
              <strong>{{ block.status }}</strong>
              <div>{{ block.id }}</div>
            </slot>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import dragula from 'dragula';

export default {
  name: 'Kanban',

  props: {
    stages: {
      type: Array,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {

    };
  },

  computed: {
    localBlocks() {
      return this.blocks;
    },
  },

  mounted() {
    dragula(this.$refs.list)
      .on('drag', (el) => {
        el.classList.add('is-moving');
      })
      .on('drop', (block, list) => {
        let index = 0;
        for (index = 0; index < list.children.length; index += 1) {
          if (list.children[index].classList.contains('is-moving')) break;
        }
        this.$emit(
          'update-block',
          block.dataset.blockId,
          list.dataset.status,
          index
        );
      })
      .on('dragend', (el) => {
        el.classList.remove('is-moving');

        window.setTimeout(() => {
          el.classList.add('is-moved');
          window.setTimeout(() => {
            el.classList.remove('is-moved');
          }, 600);
        }, 100);
      });
  },

  methods: {
    getBlocks(status) {
      return this.localBlocks.filter(block => block.status === status);
    },
    removeStage(stage) {
      this.$emit('deleteStage', stage);
    },
  },
};
</script>

<style lang="scss" scoped>
$ease-out: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

ul.drag-list,
ul.drag-inner-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.drag-container {
  max-width: 1000px;
  margin: 20px auto;
}

.drag-list {
  display: flex;
  align-items: flex-start;

  @media (max-width: 690px) {
    display: block;
  }
}

.drag-column {
  flex: 1;
  margin: 0 10px;
  position: relative;
  background: rgba(black, 0.2);
  overflow: hidden;

  @media (max-width: 690px) {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 0.8rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.drag-inner-list {
  min-height: 50px;
  color: white;
}

.drag-item {
  padding: 10px;
  margin: 10px;
  height: 100px;
  background: rgba(black, 0.4);
  transition: $ease-out;

  &.is-moving {
    transform: scale(1.5);
    background: rgba(black, 0.8);
  }
}

.drag-header-more {
  cursor: pointer;
}

.drag-options {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  transform: translateX(100%);
  opacity: 0;
  transition: $ease-out;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  &-label {
    display: block;
    margin: 0 0 5px 0;

    input {
      opacity: 0.6;
    }

    span {
      display: inline-block;
      font-size: 0.9rem;
      font-weight: 400;
      margin-left: 5px;
    }
  }
}

/* Dragula CSS  */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  list-style-type: none;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}

ul.drag-list {
  flex-wrap: wrap;
}
.drag-column {
  max-width: 150px;
  margin: 2px 1px;
  flex: auto;
  padding: 0 2px;
}
.drag-column .drag-inner-list {
  min-height: 20px;
}
.drag-column-header {
  padding: 10px 0;
  justify-content: center;
}
.drag-column h2 {
  font-size: 0.6rem;
}
.drag-item {
  padding: 10px 2px;
  text-align: center;
  height: auto;
  margin: 4px 2px;
}
.drag-item div {
  font-size: 0.7rem;
  line-height: 1;
}
</style>
