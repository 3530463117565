import budgetTemplate from '@/static/templates/budgetTemplate';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default {
  namespaced: true,
  state: {
    budgets: [],
    budget: null,
    budgetCategories: [],
    budgetTemplate,
    budgetCategoryId: null,
    persons: [],
    numberPages: 0,
    plugins: [],
    plugin: {},
    hospedagem: {},
    hospedagens: [],
  },
  actions,
  mutations,
  getters,
};
