<template>
  <div>
    <partial-sobre-a-in-cuca />
    <partial-entendimento-das-necessidades :budget="budget" />
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>PROPOSTA TÉCNICA e COMERCIAL</strong>
        </h4>
        <p>A InCuca trabalhará na validação de hipóteses do negócio, consultoria tecnológica e digital e no desenvolvimento de soluções web utilizando frameworks e linguagens de programação modernas.
        Iniciaremos pelo estudo e validação, onde nas primeiras reuniões coletamos as informações relacionadas ao objetivo do negócio, validaçòes necessárias, funcionalidades pensadas e construiremos o backlog para alcançar os objetivos, que será a base para o time executar o trabalho.</p>
        <p>Esse backlog visa elencamos as prioridades fazendo com que a solução seja construída de forma estratégica para ser lançada o quanto antes no mercado e colhendo assim informações sobre uso para continuar melhorando com base no retorno dos usuários e experiências da solução.</p>
        <p>Uma vez que tivermos definido o backlog, o que talvez pode requerer algumas semanas de trabalho, iniciaremos a construção das funcionalidades, pensando em boas práticas web, metodologias ágeis, design UX (passando sempre por aprovações do cliente), programação, testes, publicação e revisão.</p>
        <p>Trabalharemos com metodologia ágil e entrega por sprint que pode levar de 1 a 2 semanas cada.</p>
        <p>Para viabilizar o trabalho, dimensionamos um banco inicial de horas com base no objetivo macro a ser alcançado e na experiência da InCuca em outros projetos. Chegamos ao montante mensal de <strong>{{ budget.projectDescriptionValue }} horas</strong> a serem trabalhadas com escopo aberto em ciclos de banco de horas. Estas horas podem ser redimensionadas a qualquer momento, desde que com aviso prévio de 30 dias e em comum acordo.</p>
        <p style="font-size: 15px"><strong>Investimento mensal:</strong> {{ totalPrice }}</p>
        <p style="font-size: 15px"><strong>Forma de pagamento:</strong> Boleto bancário</p>
        <p style="font-size: 15px"><strong>Vencimento:</strong> Dia 01 de cada mês</p>
        <p>Não há fidelidade. Solicitações de cancelamento devem ser feitas com 30 dias de antecedência e as cobranças nesse ínterim devem ser quitadas.</p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2">
          <strong>Itens adicionais (OPCIONAIS)</strong>
        </h4>
        <table class="table">
          <tbody>
            <tr style="background: black;color: white;">
              <td>Item</td>
              <td>Descrição</td>
              <td>Investimento</td>
            </tr>
            <tr>
              <td>Hospedagem</td>
              <td>Servidor em cloud, com configuração inicial de 1GB de Ram. </td>
              <td>R$ {{ HOSPEDAGEM_CLOUD }},00 (cento e oitenta reais) por mês</td>
            </tr>
            <tr>
              <td>Manutenção¹</td>
              <td>Atualizações mensais para manter o ambiente seguro e estável. Valor mensal definido de acordo com a complexidade. </td>
              <td>Começando em R$ {{ MANUTENCAO }},00 por mês após publicação.</td>
            </tr>
          </tbody>
        </table>
        <p>¹O serviço de Manutenção é exclusivo para mantermos o ambiente seguro e estável. Não contempla solicitações extras por parte do cliente, como criação de novas páginas, alteração de conteúdo, integração com outros sistemas, etc. Consideramos que essas solicitações extras tratam-se de uma evolução do ambiente e não compreendem a segurança do mesmo.<br>
          Para demandas extras que o cliente solicite após a publicação do projeto, podemos atender por meio de contratação pontual ou já definir um banco de horas mensal que pode ser consumido para esse fim. Por exemplo: Banco de 10 horas mensais por R$ 1.900,00 por mês.</p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>METODOLOGIA</strong>
        </h4>
        <p>Para o desenvolvimento do trabalho usamos metodologia ágil em um processo iterativo, dessa forma garantimos as entregas por sprints onde o cliente vai acompanhando o andamento do projeto, conforme as funcionalidades vão sendo entregues. </p>
        <p>O consumo das horas envolvem programação e fluxo de atendimento como um todo. A produção irá debitar do banco de horas do cliente todo e qualquer tipo de investimento para que o problema, dor ou necessidade seja resolvida, desde uma reunião de alinhamento, análise de novas solicitações, programação, avaliação de ambientes, etc.</p>
        <p style="text-decoration:underline">Itens a serem considerados que serão debitados das horas propostas:</p>
        <p>
          - Planejamento e gestão do backlog do cliente/produto<br>
          - Pesquisa e análise oriundas de solicitações do cliente<br>
          - Desenvolvimento (Design, Programação, Testes, Treinamento)<br>
          - Reuniões/Comunicação/Atendimento ao cliente<br>
          - Manutenção do Ambiente de Homologação<br>
          - Manutenção do Ambiente de Produção<br>
          - Correção e avaliação de Bugs<br>
          - Documentação imposta pelo contrato ou solicitada pelo cliente</p>
        ;quebra;
        <p class="mt-5"><strong>O cronograma deve ser estabelecido pelo Gerente de Projetos da InCuca.</strong><br>
          A execução do trabalho se dá remotamente. Nas etapas que envolvem reuniões, as mesmas ocorrem online (Google meeting ou outras ferramentas) e têm duração de 1 hora. <br>
          Se o cliente requerer atendimento InCompany é cobrado a parte (por hora) após o atendimento (contando a hora de saída InCuca até a hora de retorno).</p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center ecommerce">
          <strong>IMPORTANTE</strong>
        </h4>
        <p>O modelo de contratação proposto é em escopo aberto, ou seja, definiremos as entregas do projeto, os objetivos, cronogramas, funcionalidades, entre outros, e o escopo pode sofrer mudanças ao longo do desenvolvimento, conforme alinhamentos com o cliente.</p>
        <p>Nessa fase inicial, não há como definir o montante necessário de horas para entregar 100% do escopo do projeto. Estamos partindo de um escopo macro, que irá requerer ainda um melhor detalhamento que tomaremos conhecimento ao longo do projeto.</p>
        <p>Saiba mais sobre escopo aberto em nosso blog: <a
          href="https://incuca.net/projeto-escopo-aberto-ou-fechado/"
          target="_blank"
        >https://incuca.net/projeto-escopo-aberto-ou-fechado/</a>.</p>
        <p>Estando de acordo com as condições desta proposta, precisaremos dos seguintes dados para emissão de nota fiscal:</p>
        <p>CNPJ, Razão Social, Endereço, Nome completo do representante legal e CPF do mesmo.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {MANUTENCAO, HOSPEDAGEM_CLOUD} from '@/constants';
import PartialEntendimentoDasNecessidades from './PartialEntendimentoDasNecessidades.vue';
import PartialSobreAInCuca from './PartialSobreAInCuca.vue';

export default {
  components: {PartialSobreAInCuca, PartialEntendimentoDasNecessidades},
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MANUTENCAO,
      HOSPEDAGEM_CLOUD,
    };
  },
  computed: {
    totalPrice() {
      return this.budget.valueBudget.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>

<style>
</style>
