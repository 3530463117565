<template>
  <div id="app">
    <div v-if="!isStandaloneApp">
      <Full />
      <!-- <router-view></router-view> -->
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import Full from './view/containers/Full.vue';
import Panel from './view/dashboard/Panel.vue';

export default {
  name: 'App',
  components: {
    Full,
    Panel,
  },
  computed: {
    ...mapGetters(['isStandaloneApp']),
  },
  created() {
    this.$store.dispatch('async/syncLoopback');
  },
};
</script>

<style lang="scss">
@import './style/app.scss';
</style>
