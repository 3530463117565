import {EXTRA, INMAKE, EVOLUCAO, WEB} from '@/constants';

function calculateBudgetTotal(budget) {
  let hours = 0;
  let price = 0;
  const pointPrice = 200;

  if (budget && budget.id) {
    if (budget.projectDescriptionValue) {
      hours += Number(budget.projectDescriptionValue);
    }
    // se é inmake
    if (budget && budget.scope && budget.scope.steps.hospedagem &&
      budget.scope.steps.hospedagem.categoria === 'inmake') {
      hours += 4 * Number(budget.scope.steps.paginas.quantidade);
      price = hours * pointPrice;
    } else if (budget.budgetCategoryId === 1 ||
      budget.budgetCategoryId === 3 ||
      budget.budgetCategoryId === 4 ||
      budget.budgetCategoryId === 6) {
      if (budget.scope && budget.scope.steps) {
        // website, blog, ead, e-commerce
        const entries = Object.entries(budget.scope.steps);
        entries.forEach((entry) => {
        // conceito
          if (entry[0] === 'conceito') {
            if (entry[1].categoria === 'basico') {
              hours += 2 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'intermediario') {
              hours += 10 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'avancado') {
              hours += 18 * Number(budget.scope.steps.paginas.quantidade);
            }
          }
          // conteudo
          if (entry[0] === 'conteudo') {
            if (entry[1].categoria === 'basico') {
              hours += 1 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'intermediario') {
              hours += 2 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'avancado' && entry[1].idiomas) {
              hours += 2 * Number(budget.scope.steps.paginas.quantidade) *
            Number(entry[1].idiomas);
            }
          }
          // funcionalidades
          if (entry[0] === 'funcionalidades') {
            if (entry[1].length > 0) {
              entry[1].forEach((funcionalidade) => {
                if (funcionalidade.horas) {
                  hours += Number(funcionalidade.horas);
                }
              });
            }
          }
          // hospedagem
          if (entry[0] === 'hospedagem') {
            if (entry[1].categoria === 'cliente') {
              hours += 6;
            }
            if (entry[1].categoria === 'compartilhada') {
              hours += 2.7;
            }
            if (entry[1].categoria === 'cloud') {
              hours += 1.3;
            }
            if (entry[1].categoria === 'outro') {
              if (entry[1].horas) {
                hours += Number(entry[1].horas);
              }
            }
          }
          // imagens
          if (entry[0] === 'imagens') {
            if (entry[1].categoria === 'basico') {
              hours += 1 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'avancado') {
              hours += 6 * Number(budget.scope.steps.paginas.quantidade);
            }
          }
          // metodologia
          if (entry[0] === 'metodologia') {
            if (entry[1].categoria === 'linear') {
              hours += 6;
            }
            if (entry[1].categoria === 'continua') {
              hours += 4 * Number(entry[1].entregas);
            }
          }
          // migracaoDeConteudo
          if (entry[0] === 'migracaoDeConteudo') {
            if (entry[1].categoria === 'intermediario') {
              hours += 4;
            }
            if (entry[1].categoria === 'avancado' && entry[1].horas) {
              hours += Number(entry[1].horas);
            }
          }
          // redirects
          if (entry[0] === 'redirects') {
            if (entry[1].quantidade) {
              hours += entry[1].quantidade * 0.16667;
            }
          }
          // responsividade
          if (entry[0] === 'responsividade') {
            if (entry[1].categoria === 'intermediario') {
              hours += 5 * Number(budget.scope.steps.paginas.quantidade);
            }
            if (entry[1].categoria === 'avancado') {
              hours += 10 * Number(budget.scope.steps.paginas.quantidade);
            }
          }
          // treinamento
          if (entry[0] === 'treinamento') {
            if (entry[1].horas) {
              hours += Number(entry[1].horas);
            }
          }

          // e-commerce e ead
          // gateway
          if (budget.budgetCategoryId === 4 || budget.budgetCategoryId === 3) {
            if (entry[0] === 'gateway') {
              if (entry[1].categoria === 'intermediario') {
                hours += 4;
              }
              if (entry[1].categoria === 'avancado' && entry[1].horas) {
                hours += Number(entry[1].horas);
              }
            }
          }
          // e-commerce
          // entrega
          if (budget.budgetCategoryId === 4) {
            if (entry[0] === 'entrega') {
              if (entry[1].categoria === 'basico') {
                hours += 3;
              }
              if (entry[1].categoria === 'intermediario') {
                hours += 5;
              }
              if (entry[1].categoria === 'avancado' && entry[1].horas) {
                hours += Number(entry[1].horas);
              }
            }
          }
        });
      }
      price = hours * pointPrice;
    }
    // pesquisa
    if (budget.budgetCategoryId === 2) {
      price = hours * WEB;
    }
    // EVOLUCAO
    if (budget.budgetCategoryId === 13) {
      price = hours * EVOLUCAO;
    }
    // PERFORMANCE
    if (budget.budgetCategoryId === 17) {
      price = hours * EVOLUCAO;
    }
    // INMAKE
    if (budget.budgetCategoryId === 14) {
      price = hours * INMAKE;
    }
    // SOLUCAO WEB
    if (budget.budgetCategoryId === 15) {
      price = hours * WEB;
    }
    // EXTRAS
    if (budget.budgetCategoryId === 16) {
      price = hours * EXTRA;
    }
  }
  return {hours, price};
}

export default calculateBudgetTotal;
