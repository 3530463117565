<template>
  <div class="app flex-row align-items-center">
    <div class="row justify-content-center align-content-center">
      <div class="card">
        <div class="card-block p-4">
          <form>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Digite seu e-mail"
                required>
            </div>
            <div
              v-if="success.message"
              class="alert alert-success"
              role="alert">
              {{ success.message }}
            </div>
            <div
              v-if="error.message"
              class="alert alert-danger"
              role="alert">{{ error.message }}</div>
            <div
              class="flex"
              style="margin-top: 25px;">
              <router-link
                to="/login"
                class="link">Voltar</router-link>
              <button
                type="submit"
                class="btn btn-primary btn-lg"
                @click="onSubmit">
              <i class="fa fa-lock"/> REDEFINIR SENHA</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      email: '',
      error: {
        message: '',
      },
      success: {
        message: '',
      },
    };
  },
  methods: {
    onSubmit(evt) {
      this.success.message = '';
      this.error.message = '';
      evt.preventDefault();
      this.$store.dispatch('auth/resetPassword', {
        email: this.email,
        success: () => {
          this.success.message = 'E-mail de redefinição de senha enviado.';
        },
        error: (error) => {
          this.error = error;
        },
      });
    },
  },
};
</script>

<style scoped>
.app,
.row.justify-content-center.align-content-center {
  height: 100vh;
  width: 100vw;
}
.card-img-top {
  padding: 15px;
}
.card {
  width: 400px;
  align-self: center;
}
.flex {
  display: flex;
}
.form-group label {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 14px;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 0;
}
.form-control:focus {
  box-shadow: none;
}
.btn-primary {
  color: #fff;
  background-color: #4f5d73;
  border-color: #4f5d73;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
  flex: 1;
}
.link {
  color: #495057;
  font-size: 14px;
  flex: 1;
  align-self: center;
}
</style>
