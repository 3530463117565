export function setBudget(state, budget) {
  state.budget = budget;
}
export function setFinance(state, finance) {
  state.finance = finance;
}

export function setInstallment(state, installment) {
  state.installments = installment;
}

export function updateInstallment(state, Installment) {
  state.installment = {...state.Installment, ...Installment};
}

export function createInstallment(state, Installment) {
  state.installment = Installment;
}

export function setInstallments(state, Installments) {
  state.installments = Installments;
}
