import lb from '@/services/loopback';

export default {
  namespaced: true,
  actions: {
    getHospedagens() {
      return lb
        .find('/Hospedagems');
    },

    getHospedagem(_, id) {
      return lb
        .find(`/Hospedagems/${id}`);
    },

    upsertHospedagem(_, plugin) {
      const updatedPlugin = plugin;
      if (updatedPlugin.id) {
        const id = updatedPlugin.id;
        return lb.patch(`/Hospedagems/${id}`, updatedPlugin);
      }
      return lb
        .post('/Hospedagems', updatedPlugin);
    },

    removeHospedagem(_, plugin) {
      return lb
        .delete(`/Hospedagems/${plugin.id}`);
    },

    searchHospedagens(_, term) {
      return lb.find('/Hospedagems', {
        where: {name: {like: `%${term}%`}},
      });
    },

  },
};

