<template>
  <div>
    <div class="text-center">
      <h4>Qual o gateway de pagamento?</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="custom-form">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.gateway.categoria"
            buttons
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="
            patchEscopoAttributes({event:$event, item:'gateway'})">
            <b-form-radio value="basico">
              Não incluso
            </b-form-radio>
            <b-form-radio value="intermediario">
              Paypal e transferência bancária
            </b-form-radio>
            <b-form-radio value="avancado">
              Outro
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="budget.scope.steps.gateway.categoria === 'avancado'"
      class="item-content">
      <h4>Informar o número de horas:</h4>
      <b-form-input
        id="qtd-entregas"
        :value="budget.scope.steps.gateway.horas"
        class="mb-15"
        type="number"
        size="lg"
        @input="setStepAnalise(
      {item: 'gateway', att: 'horas', event: $event})"/>
      <h4 style="margin-top: 20px;">Informar a descrição da análise:</h4>
      <wysiwyg
        :html="budget.scope.steps.gateway.textoAnalise"
        placeholder="Enter something"
        @change="setStepAnalise(
      {item: 'gateway', att: 'textoAnalise', event: $event})"/>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
      'setStepAnalise',
    ]),
    onInput($event, att) {
      this.setStepAnalise({
        item: 'gateway',
        event: $event,
        att,
      });
    },
  },
};
</script>

<style lang="scss">
.custom-form /deep/ span {
  white-space: normal !important;
}
.custom-form {
  text-align: center;
}
.obs {
  text-align: center;
  width: 100%;
}
h4 {
    margin-bottom: 10px;
}
</style>
