import moment from 'moment';
import calculateBudgetTotal from '@/utils/calculateBudgetTotal';

export function pointPrice() {
  return 90;
}

export function budgetTotal(state) {
  return calculateBudgetTotal(state.budget);
}

export function getInstallments(state) {
  if (!state.installments) return [];
  return state.installments;
}

export function shownInstallments(state) {
  const itemsInstallments = [];
  const installments = state.installments;
  if (installments.length > 0) {
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < installments.length; i++) {
      itemsInstallments.push({
        numero: i,
        dataParcela: moment(installments[i].dueDate).format(
          'DD/MM/YYYY'
        ),
        valor: installments[i].value,
      });
    }
    return itemsInstallments;
  }
  return itemsInstallments;
}
