module.exports = {
  apps: [
    {
      name: 'comercial',
      script: 'index.js',
      env: {
        ENTERPRISE_URL: 'https://api.pipedrive.com/',
        PIPEDRIVE_TOKEN: '5aa90d3145256ca6e9fb006133c445fbcf047082',
      },
      envProduction: {
        NODE_ENV: 'production',
        PIPEDRIVE_TOKEN: '5aa90d3145256ca6e9fb006133c445fbcf047082',
      },
    },
  ],
};
