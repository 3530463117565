import Vue from 'vue';

export function getBudget({dispatch, commit}, {id, next}) {
  dispatch('budget/getBudget', id, {root: true})
    .then((budget) => {
      commit('setBudget', budget);
      next();
    });
}

// atualiza a model do budget
export function upsert({dispatch}, $event) {
  dispatch('budget/upsertBudget', $event, {root: true})
    .then((bud) => {
      dispatch('budget/getBudget', bud.id, {root: true});
      Vue.toasted.success('Orçamento atualizado com sucesso');
      return bud;
    })
    .catch(Vue.toasted.error);
}
