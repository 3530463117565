<template>
  <div
    v-if="budget.budgetCategoryId &&
    budget.budgetCategoryId == 2">
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2">
          <strong>Ferramentas e metodologias</strong>
        </h4>
        <span class="text">As ferramentas e metodologias utilizadas serão escolhidas de acordo
        com a necessidade da solução e do negócio digital, escolhidas pela equipe.</span>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-3">
      <b-col>
        <h4 class="exo2">
          <strong>Cronograma</strong>
        </h4>
        <span class="text">O cronograma será estabelecido entre o time
        após a primeira reunião do projeto conforme forem definidas as soluções e validações.<br>
          As horas contabilizadas no projeto são de
          cada integrante da equipe InCuca.</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>
