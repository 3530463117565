import lb from '@/services/loopback';

export function getInstallments(_, id) {
  return lb
    .find(`/Budgets/${id}/installments`);
}

export function createInstallments(_, installments) {
  return lb
    .post('/Installments', installments);
}

export function deleteInstallments(_, installments) {
  const promises = [];

  installments.forEach(installment => promises.push(lb
    .delete(`/Installments/${installment.id}`)));

  return Promise.all(installments);
}
