import calculateBudgetTotal from '@/utils/calculateBudgetTotal';

export function budgets(state, rootGetters) {
  if (state.budget) return state.budgets;
  return rootGetters['budget/budget'];
}

export function budgetCategory(state) {
  if (!state.budget) return null;
  return state.budget.budgetCategoryId;
}

export function pointPrice() {
  return 90;
}

export function budgetTotal(state) {
  return calculateBudgetTotal(state.budget);
}

export function budget(state) {
  if (state.budget && state.budget.id) return state.budget;
  return state.budgetTemplate;
}

export function settedCategoryLevel(state) {
  return (category) => {
    let returnLevel = 'basico';
    const levels = ['basico', 'intermediario', 'avancado'];
    levels.forEach((level) => {
      if (state.budget && state.budget.scope && state.budget.scope.steps) {
        const valor = state.budget.scope.steps[category][level].valor;
        if (valor !== 0) {
          returnLevel = level;
        }
      }
    });
    return returnLevel;
  };
}

export function budgetCategories(state) {
  return state.budgetCategories;
}

export function plugins(state) {
  return state.plugins;
}

export function plugin(state) {
  return state.plugin;
}

export function hospedagem(state) {
  return state.hospedagem;
}

export function hospedagens(state) {
  return state.hospedagens;
}

export function isProducao() {
  // if (state.budget && (
  //   state.budget.budgetCategoryId === 1 ||
  //   state.budget.budgetCategoryId === 4 ||
  //   state.budget.budgetCategoryId === 6 ||
  //   state.budget.budgetCategoryId === 3)) {
  //   return true;
  // }
  return false;
}

export function isPesquisa(state) {
  if (state.budget && (state.budget.budgetCategoryId === 2)) return true;
  return false;
}

export function isSuporte(state) {
  if (state.budget && (
    state.budget.budgetCategoryId === 8 ||
    state.budget.budgetCategoryId === 11 ||
    state.budget.budgetCategoryId === 12)) {
    return true;
  }
  return false;
}

export function persons(state) {
  return state.persons;
}
