import router from '@/router';
import Vue from 'vue';
import {clone} from 'lodash';

// atualiza a model do budget
export function upsert({dispatch, commit}, $event) {
  dispatch('budget/upsertBudget', $event, {root: true})
    .then((bud) => {
      commit('setBudget', bud);
      Vue.toasted.success('Escopo atualizado com sucesso');
      return bud;
    })
    .catch(Vue.toasted.error);
}

// cria uma instancia modelo de budget
export function createBudget({dispatch, commit}) {
  return dispatch('budget/createBudget', {}, {root: true})
    .then((bud) => {
      commit('setBudget', bud);
      router.push({name: 'Criar escopo', params: {id: bud.id}});
      // instancia modelo criada com sucesso.
      return bud;
    })
    .catch(Vue.toasted.error);
}

export function searchTerms(
  {dispatch, commit, state},
  term = state.searchedTerms,
) {
  commit('setSearchedTerms', term);
  dispatch('budget/search', term, {root: true})
    .then((budgets) => {
      commit('setBudgets', budgets);
    });
}

export function removeBudget({dispatch, commit}, budget) {
  dispatch('budget/removeBudget', budget, {root: true})
    .then(() => commit('removeBudget', budget))
    .then(() => { dispatch('searchTerms'); });
}

export function duplicateBudget({dispatch}, budget) {
  const newBudget = clone(budget);
  delete newBudget.id;
  delete newBudget.dueDate;
  delete newBudget.description;
  delete newBudget.history;
  newBudget.projectName = 'A definir';
  delete newBudget.sendDate;
  delete newBudget.solicitation;
  delete newBudget.status;
  delete newBudget.valueBudget;
  delete newBudget.scopeInnerHTML;
  delete newBudget.budgetInnerHTML;
  newBudget.created = new Date();
  dispatch('budget/duplicateBudget', newBudget, {root: true})
    .then((bud) => {
      router.push({name: 'Criar escopo', params: {id: bud.id}});
    })
    .catch(() => {
      Vue.toasted.error('Erro ao criar escopo.');
    });
}

