<template>
  <div>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>SOBRE A INCUCA</strong>
        </h4>
        <p>A InCuca é uma consultoria para negócios digitais que cria ecossistemas favoráveis para ambientes digitais evoluírem. Da análise a estratégia, do protótipo a solução e além da transformação digital. A empresa desenvolve soluções digitais e tecnológicas sob medida para pessoas, empresas e projetos.</p>
        <p>São empregadas diversas tecnologias de informação e comunicação – TICs para dinamizar, agilizar e melhorar processos no mercado para promover a economia e a melhora contínua dos sistemas e das empresas na internet.</p>
        <p>Saiba mais em nosso site: <a
          target="_blank"
          href="https://incuca.net/"
        >https://incuca.net</a></p>

        <h5>Equipe</h5>
        <p>A InCuca conta com um time multidisciplinar, que atuaram em milhares de projetos de diversas complexidades. Não terceirizamos a mão-de-obra, todos os projetos são desenvolvidos pelo próprio time da InCuca.</p>
        <p>Conheça os makers no site: <a
          target="_blank"
          href="https://incuca.net/incuca/"
        >https://incuca.net/incuca/</a></p>
        <h5>Cases</h5>
        <p>Alguns dos projetos desenvolvidos pela InCuca:</p>
        <p class="mb-0"><a
          href="https://marketplace.rdstation.com/"
          target="_blank"
        >Marketplace dos Parceiros RD Station</a></p>
        <p class="mb-0"><a
          href="https://memoria.wilsonsons.com.br/"
          target="_blank"
        >Software do Centro de Memórias Wilson Sons</a></p>
        <p class="mb-0"><a
          href="https://playlab.com.br/"
          target="_blank"
        >E-commerce da Playlab</a></p>
        <p class="mb-0"><a
          href="https://beenergy.com.br/"
          target="_blank"
        >Site e Software da Beenergy</a></p>
        <p><a
          href="https://guiadaalma.com.br/"
          target="_blank"
        >Marketplace do Guia da Alma</a></p>
        <p>Confira outros cases no site <a
          href="https://incuca.net/cases/"
          target="_blank"
        >https://incuca.net/cases/</a></p>
      </b-col>
    </b-row>
    ;quebra;
  </div>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>
