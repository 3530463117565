export function budgetTemplate() {
  return {
    id: '',
    projectName: '',
    projectDescription: '',
    projectDescriptionValue: '',
    budgetCategory: '',
    client: {
      name: '',
      email: '',
      phone: '',
      solicitation: '',
      history: '',
    },
    valueBudget: '',
    scope: {
      mapa: {
        stages: ['home'],
        blocks: [
          {
            id: 0,
            status: 'home',
            title: 'não considerar',
          },
        ],
      },
      steps: {
        paginas: {
          quantidade: 0,
        },
        blog: false,
        metodologia: {
          categoria: 'linear',
        },
        conteudo: {
          categoria: 'basico',
        },
        treinamento: {
          horas: 1,
        },
        responsividade: {
          categoria: 'basico',
        },
        conceito: {
          categoria: 'basico',
        },
        imagens: {
          categoria: 'basico',
        },
        migracaoDeConteudo: {
          categoria: 'basico',
        },
        gateway: {
          categoria: 'basico',
        },
        entrega: {
          categoria: 'basico',
        },
        funcionalidades: [],
        hospedagem: {
          categoria: 'compartilhada',
        },
        redirects: {
          quantidade: 0,
        },
      },
    },
  };
}

export function budget(state) {
  if (state.budget && state.budget.id) return state.budget;
  return budgetTemplate();
}
