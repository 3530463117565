<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="form-group">
              <input
                :value="searchedTerms"
                type="text"
                name="search"
                placeholder="Pesquisar orçamentos"
                class="form-control"
                @input="onSearchTerms"
              >
            </div>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              @click="createBudget">
              <i class="fa fa-plus"/>&nbsp; Criar proposta
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <div
            v-for="budget in budgets"
            :key="budget.id"
            class="col-md-4">
            <b-card
              :title="budget.projectName"
              :sub-title="'REF ' + budget.id + ' - ' + category(budget)"
              bg-variant="white"
              text-variant="dark">
              <b-button
                variant="link"
                class="card-link duplicate p-0"
                @click="duplicateBudget(budget)">
                <i class="fa fa-redo"/>
              </b-button>
              <b-link
                v-b-modal="`${budget.id}`"
                href="#"
                class="card-link delete">
                <i class="fa fa-trash"/>
              </b-link>
              <router-link
                :to="{ name: 'Criar escopo', params: { id: budget.id } }"
                class="card-link edit">
                <i class="fa fa-pencil-alt"/>
              </router-link>
              <hr>
              <div
                v-if="budget.client"
                class="card-text">
                <p>{{ budget.client.name ? budget.client.name : 'Faltou cadastrar o nome' }}
                  &lt;{{ budget.client.email ? budget.client.email : 'Faltou cadastrar o e-mail' }}
                  &gt;</p>
              </div>
              <hr>
              <router-link
                v-if="shouldntShowScope(budget)"
                :to="{name: 'Visualizar escopo', params: {id: budget.id}}"
                class="btn btn-primary">
                Escopo
              </router-link>
              <!-- <router-link
                v-if="shouldntShowBudget(budget)"
                :to="{name: 'Visualizar orçamento', params: {id: budget.id}}"
                class="btn btn-primary">
                Orçamento
              </router-link> -->
              <router-link
                v-if="shouldntShowSimulator(budget)"
                :to="{
                  name: 'Orçamento',
                  params: {
                    id: budget.id
                  }
                }"
                class="btn btn-primary">
                Simulador
              </router-link>

            </b-card>
            <b-modal
              :id="`${budget.id}`"
              size="sm"
              header-bg-variant="danger"
              cancel-variant="light"
              cancel-title="Cancelar"
              ok-variant="danger"
              title="Remover orçamento"
              @ok="removeBudget(budget)">
              <p class="my-2">Deseja realmente remover o orçamento?<br>
                <strong>Essa ação não possui volta.</strong>
              </p>
            </b-modal>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import debounce from 'debounce';

export default {
  name: 'ListBudgets',
  computed: {
    ...mapState('listBudgets', ['searchedTerms']),
    ...mapGetters('listBudgets', ['budgets']),
  },
  created() {
    this.$store.dispatch('listBudgets/searchTerms');
  },
  methods: {
    ...mapActions('listBudgets', [
      'budget',
      'removeBudget',
      'createBudget',
      'duplicateBudget',
    ]),
    category(budget) {
      if (budget.budgetCategory) return budget.budgetCategory.name;
      return '';
    },
    hasValueBudget(budget) {
      if (budget && budget.valueBudget) return true;
      return false;
    },
    shouldntShowScope(budget) {
      if (!budget.budgetCategoryId) return false;
      return true;
    },
    shouldntShowBudget(budget) {
      if (budget.budgetCategoryId === 9 ||
      budget.budgetCategoryId === 11 ||
      budget.budgetCategoryId === 12 ||
      !budget.budgetCategoryId) return false;
      return true;
    },
    shouldntShowSimulator(budget) {
      if (budget.budgetCategoryId === 8 ||
      !budget.budgetCategoryId) return false;
      return true;
    },
    onSearchTerms: debounce(function($event) {
      this.$store.dispatch('listBudgets/searchTerms', $event.target.value);
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
h4.card-title[data-v-37aefc26] {
    font-size: 1rem;
    max-width: 70%;
}
.card-link.delete {
  position: absolute;
  top: 20px;
  right: 25px;
}
.card-link.edit {
  position: absolute;
  top: 20px;
  right: 55px;
}
.card-link.duplicate {
  position: absolute;
  top: 20px;
  right: 85px;
}
</style>
