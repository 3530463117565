export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-th-large',
    },
    {
      name: 'Propostas',
      url: '/budgets',
      icon: 'fa fa-money-bill-alt',
    },
    // {
    //   name: 'Contratos',
    //   url: '/contracts',
    //   icon: 'fa fa-file-alt',
    // },
    {
      name: 'Análises',
      url: 'https://analise.incuca.net',
      icon: 'fa fa-tachometer-alt',
    },
  ],
};
