import Vue from 'vue';
import Vuex from 'vuex';

import async from './modules/async';
import auth from './modules/auth';
import budget from './modules/budget';
import installment from './modules/installment';
import pipedrive from './modules/pipedrive';
import listBudgets from './modules/listBudgets';
import upsertBudget from './modules/upsertBudget';
import viewBudget from './modules/viewBudget';
import viewBudgetScope from './modules/viewBudgetScope';
import budgetSimulation from './modules/budgetSimulation';
import dashboard from './modules/dashboard';
import plugin from './modules/plugin';
import hospedagem from './modules/hospedagem';

Vue.use(Vuex);

const state = {};
const getters = {
  isStandaloneApp(stat) {
    return stat.route.meta.standalone;
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations: {},
  modules: {
    auth, // auth namespaced
    async, // async namespaced
    budget, // budget namespaced
    installment, // installment namespaced
    pipedrive, // pipedrive namespaced
    listBudgets, // listBugets namespaced
    upsertBudget, // upsertBudget namespaced
    viewBudget, // viewBudget namespaced
    viewBudgetScope, // viewBudgetScope namespaced
    budgetSimulation, // budgetSimulation namespaced
    dashboard, // dashboard namespaced
    plugin, // plugin plugin
    hospedagem, // hospedagem hospedagem
  },
});

