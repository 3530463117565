import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

// view
import Login from './view/Login.vue';
import NotFound from './view/Page404.vue';
import ForgotPassword from './view/ForgotPassword.vue';
import Dashboard from './view/Dashboard.vue';
import ListBudgets from './view/budgets/ListBudgets.vue';
import UpsertBudgetScope from './view/budgets/UpsertBudgetScope.vue';
import ViewBudgetScope from './view/budgets/ViewBudgetScope.vue';
import ViewBudget from './view/budgets/ViewBudget.vue';
import BudgetSimulation from './view/budgets/BudgetSimulation.vue';
import Contracts from './view/Contracts.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      // component: Full,
      redirect: {name: 'dashboard'},
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {standalone: true},
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {requiresAuth: true},
    },
    {
      path: '/forgot-password',
      name: 'Esqueci minha senha',
      component: ForgotPassword,
      meta: {standalone: true},
    },
    {
      path: '/budgets',
      name: 'Orçamentos',
      component: ListBudgets,
      meta: {requiresAuth: true},
    },
    {
      path: '/budgets/scope/create/:id',
      name: 'Criar escopo',
      props: true,
      component: UpsertBudgetScope,
      meta: {requiresAuth: true},
    },
    {
      path: '/budgets/scope/view/:id',
      name: 'Visualizar escopo',
      props: true,
      component: ViewBudgetScope,
      meta: {requiresAuth: true},
    },
    {
      path: '/budgets/view/:id',
      name: 'Visualizar orçamento',
      props: true,
      component: ViewBudget,
      meta: {requiresAuth: true},
    },
    {
      path: '/budgets/simulation/:id',
      name: 'Simulador de orçamento',
      props: true,
      component: BudgetSimulation,
      meta: {requiresAuth: true},
    },
    {
      path: '/budgets/simulation/:id/:finance',
      name: 'Orçamento',
      props: true,
      component: BudgetSimulation,
      meta: {requiresAuth: true},
    },
    {
      path: '/contracts',
      name: 'Contratos',
      props: true,
      component: Contracts,
      meta: {requiresAuth: true},
    },
    {
      path: '*',
      name: 'Não encontrado',
      component: NotFound,
      meta: {standalone: true},
    },
  ],
});

// Sync routes with auth module
store.dispatch('auth/syncRouter', router);

export default router;
