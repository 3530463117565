<template>
  <b-row class="d-flex align-items-center">
    <b-col md="8">
      <h5>
        <span style="margin-left:-2px">
          IN</span><span style="font-weight:bold">CUCA</span>
        TECH -
        <strong>{{ currentDate }}</strong>
      </h5>
      <h3 class="mb-0">
        <strong>
          PROPOSTA COMERCIAL
        </strong>
      </h3>
      <h5 class="mb-0">
        <strong>
          <span class="website text-uppercase">
            {{ budget.budgetCategory && budget.budgetCategory.name }}
          </span>
          REF {{ budget.id }}
        </strong>
      </h5>
    </b-col>
    <b-col
      md="2"
      class="px-0"
    />
    <b-col
      md="2"
      class="px-0"
    >
      <img
        class="w-100"
        src="/static/img/incuca-menu.png"
      >
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    budget: {
      type: Object,
      default: () => ({
        budgetCategory: {},
      }),
    },
  },
  computed: {
    currentDate() {
      return moment(this.budget.updated).format('DD/MM/YYYY');
    },
  },
};
</script>

<style>
</style>
