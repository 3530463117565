<template>
  <div>
    <div class="text-center">
      <h4 style="margin-bottom: 30px;">
        Existe alguma funcionalidades (componente)
        diferente que você espera em seu site?</h4>
    </div>
    <b-row>
      <b-form-checkbox-group
        id="checkbox-plugins"
        :checked="budget.scope.steps.funcionalidades"
        name="plugins"
        @input="onClickCheckbox($event)">
        <b-form-checkbox
          v-for="plugin in plugins"
          :value="plugin"
          :key="plugin.id">{{ plugin.name }}</b-form-checkbox>
      </b-form-checkbox-group>
    </b-row>
    <b-row class="button-add-plugin">
      <b-button @click="toggleAddPlugin">
        <span v-if="!show">Adicionar novo plugin</span>
        <span v-if="show">Esconder formulário</span>
      </b-button>
    </b-row>
    <b-row
      v-if="show"
      class="form-add-plugin">
      <b-form
        @submit="onSubmit">
        <!-- nome -->
        <b-form-group
          id="pluginName"
          label="Nome do Plugin:"
          label-for="pluginName">
          <b-form-input
            id="pluginNameInput"
            :value="plugin.name"
            type="text"
            required
            placeholder="Nome do Plugin"
            @change="patchPluginAttributes({name: $event})"/>
        </b-form-group>
        <!-- link -->
        <b-form-group
          id="pluginLink"
          label="Link para página do Plugin"
          label-for="pluginLink">
          <b-form-input
            id="pluginLinkInput"
            :value="plugin.link"
            type="text"
            required
            placeholder="Link para página do plugin"
            @change="patchPluginAttributes({link: $event})"/>
        </b-form-group>
        <!-- horas -->
        <b-form-group
          id="pluginDescricao"
          label="Quantidade de horas para:"
          label-for="pluginHoras">
          <b-form-input
            id="pluginHorasInput"
            :value="plugin.horas"
            type="number"
            required
            placeholder="Informe a quantidade de horas para configurar"
            @change="patchPluginAttributes({horas: $event})"/>
        </b-form-group>
        <!-- descricao -->
        <b-form-group
          id="pluginDescricao"
          label="Descrição do Plugin:"
          label-for="pluginDescription">
          <b-form-textarea
            id="pluginDescricaoTextarea"
            :rows="3"
            :max-rows="6"
            :value="plugin.descricao"
            required
            placeholder="Insira uma descrição"
            @input="patchPluginAttributes({descricao: $event})"/>
        </b-form-group>
        <!-- submit -->
        <b-button
          type="submit"
          variant="primary">Cadastrar Plugin</b-button>
      </b-form>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
      'plugins',
      'plugin',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'setStepTextoAnalise',
      'patchEscopoAttributes',
      'patchPluginAttributes',
    ]),
    ...mapActions('upsertBudget', [
      'createPlugin',
    ]),
    onClickCheckbox($event) {
      this.patchEscopoAttributes({event: $event, item: 'funcionalidades'});
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.createPlugin();
    },
    toggleAddPlugin() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row.button-add-plugin {
    margin-top: 35px;
    margin-bottom: 30px;
}
.row.form-add-plugin form {
  width: 100%;
  max-width: 600px;
}
.row.form-add-plugin {
  margin-bottom: 50px;
}
</style>
