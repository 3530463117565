import Vue from 'vue';

export function getBudget({dispatch, commit}, id) {
  return dispatch('budget/getBudget', id, {root: true})
    .then((budget) => {
      commit('setBudget', budget);
    });
}

export function getInstallments({dispatch, commit}, id) {
  return dispatch('installment/getInstallments', id, {root: true})
    .then((installment) => {
      commit('setInstallment', installment);
    });
}

export function createInstallments({dispatch, commit}, installments) {
  dispatch('installment/createInstallments', installments, {root: true})
    .then((Installment) => {
      commit('createInstallment', Installment);
    })
    .catch((error) => {
      Vue.toasted.error('Não foi possível criar parcelamento.', error);
    });
}

export function setupStore({dispatch, commit}, {to, next}) {
  commit('setFinance', to.params.finance);
  Promise.all([
    dispatch('getInstallments', to.params.id),
    dispatch('getBudget', to.params.id),
  ]).then(next);
}
