import router from '@/router';
import Vue from 'vue';

export function onRouteEnter({dispatch}) {
  dispatch('loadDealsDataBySI');
}

export function loadDealsDataBySI({dispatch, commit}) {
  dispatch('pipedrive/loadDealsDataBySI', null, {root: true})
    .then((data) => {
      commit('values', data);
    });
}

export function createBudget({dispatch, commit, rootGetters}) {
  const budget = Object.assign({}, rootGetters['budget/budgetTemplate']);
  delete budget.id;
  return dispatch('budget/createBudget', budget, {root: true})
    .then((bud) => {
      commit('setBudget', bud);
      router.push({name: 'Criar escopo', params: {id: bud.id}});
      // instancia modelo criada com sucesso.
      return bud;
    })
    .catch(Vue.toasted.error);
}

export function teste({commit}) {
  const data = {
    173: 1,
    174: 2,
    175: 3,
    176: 5,
    177: 0,
    178: 0,
    179: 0,
    180: 0,
    181: 0,
    182: 0,
    183: 0,
    184: 0,
  };
  commit('values', data);
}
