<template>
  <div class="app flex-row align-items-center">
    <div class="flex flex-wrap justify-content-center align-content-center">
      <div class="card">
        <div class="card-block p-4">
          <form>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Digite seu e-mail"
                required>
            </div>
            <div class="form-group">
              <label for="senha">Senha</label>
              <input
                id="senha"
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Digite sua senha"
                required>
            </div>
            <div
              v-if="error.message"
              class="alert alert-danger"
              role="alert">{{ error.message }}</div>
            <div class="flex">
              <router-link
                to="/forgot-password"
                class="link">Esqueceu sua senha?</router-link>
              <button
                :disabled="isLoading"
                type="submit"
                class="btn btn-primary"
                @click="onSubmit">
                <i :class="loadingClasses | icon('check')" /> ENTRAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingState from '@/mixins/loadingState';

export default {
  mixins: [
    loadingState,
  ],
  data() {
    return {
      email: '',
      password: '',
      error: {
        message: '',
      },
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.$store
        .dispatch('auth/signIn', {
          email: this.email,
          password: this.password,
        })
        .catch((err) => { this.error = err; })
        .finally(() => { this.isLoading = false; });
    },
  },
};
</script>

<style lang="scss" scoped>
.app,
.justify-content-center.align-content-center {
  height: 100vh;
  width: 100vw;
}
.card-img-top {
  padding: 15px;
}
.card {
  width: 400px;
  align-self: center;
}
.flex {
  display: flex;
}
.form-group label {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 14px;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  display: flex;
}
.form-control:focus {
  box-shadow: none;
}
.btn-primary {
  color: #fff;
  background-color: #495057;
  border-color: #495057;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
  max-width: 140px;
  flex: 1;
}
.link {
  color: #495057;
  font-size: 14px;
  flex: 1;
  align-self: center;
}
</style>
