<template>
  <div>
    <b-row>
      <b-col>
        <h3>Informar a quantidade de redirects:</h3>
        <b-form-input
          id="qtd-horas"
          :value="budget.scope.steps.redirects.quantidade"
          class="mb-15"
          type="number"
          size="lg"
          @input="patchEscopoAttributes({event:$event, item: 'redirects'})"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
    ]),
  },
};
</script>
