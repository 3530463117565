<template>
  <div>
    <!-- eslint-disable max-len -->
    <partial-sobre-a-in-cuca />
    <partial-entendimento-das-necessidades :budget="budget" />
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>PROPOSTA TÉCNICA</strong>
        </h4>
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <p>Com foco em conquistar os objetivos e metas do cliente de crescimento na internet a Evolução digital apresenta consultoria estratégica, data driven e tecnológica para escalar negócios na web, otimizando ambientes e métricas orgânicas, melhorar posicionamento, engajando e facilitando a jornada do cliente e resultados em mercados competitivos.</p>

        <p>Neste serviço contínuo da InCuca o cliente é conduzido pela análise de dados à Evolução Digital.</p>

        <p>Dentro desse contexto o desafio inicial é entender o real problema do cliente e buscar compreender como ele está digitalmente nos principais pilares de evolução: Plataforma otimizada, SEO, Performance, Conversão, Usabilidade, Segurança e Integrações com outros sistemas, analisando os dados já coletados se existirem ou iniciando a configuração da coleta de dados.<br>
          Este trabalho nos direciona para tirar o melhor proveito dos insights e oportunidades identificadas no andamento do trabalho e assim potencializar a presença digital e resultados.</p>

        <p>Por exemplo, a <u>Consultoria em SEO e Otimização de Conversão</u> é um dos serviços mais importantes na evolução do seu produto digital tendo em vista a sua importância na geração e retenção de oportunidades com base em tráfego orgânico (Google), ou seja, sem investimento em mídia paga. Ao mesmo tempo, um bom trabalho de SEO ajuda a melhorar o posicionamento da sua marca na internet (branding), fortalecendo a sua presença digital.</p>

        <p>Na execução deste serviço analisamos todos os aspectos técnicos, de conteúdo, relevância e de captação no website, identificando assim as necessidades de ajustes e/ou melhorias em prol de um objetivo claro de aumento de tráfego e conversão. <br>
          Organizamos a implementação do nosso plano de SEO e otimização de conversão em 5 (cinco) etapas, a saber:<br>
        </p>
        <p style="font-size: 16px;"><strong>1 - Diagnóstico e ajustes iniciais</strong></p>
        <p style="font-size: 15px">
          <strong>Objetivo</strong>
        </p>
        <p>
          Nesta etapa procuramos apontar os principais ajustes a serem feitos para garantir que as páginas web do seu ambiente digital estejam o mais adequadas possíveis aos motores de busca nos critérios de rankeamento (Google). Na análise também nos atemos aos pontos de captação (formulários, CTAs, landing-pages), atentando para os requisitos mínimos de configuração, funcionamento e um padrão aceitável de usabilidade. A prioridade aqui é verificar se o seu ambiente está preparado para o salto de evolução digital.</p>

        <p style="font-size: 15px">
          <strong>Como funciona</strong>
        </p>
        <p>
          Primeiramente realizamos uma análise completa do seu ambiente digital e domínio a fim de identificar a autoridade e o posicionamento atual do seu negócio nos motores de busca (domain auhtority), e os problemas técnicos que impactam negativamente no rankeamento de SEO e geração de leads.</p>
        ;quebra;<br>
        <p class="pt-5">As atividades de auditoria de SEO são focadas nos ajustes basilares de otimizações de SEO On-page, que são correções e melhorias de caráter técnico das páginas web. Já as de conversão baseiam-se precisamente em dados de navegação (fluxo de comportamento de usuário, mapas de calor), caso esses existam, e em heurísticas de usabilidade.<br>
          O esforço empregado nessa fase do projeto é avaliado de acordo com o volume de oportunidades de melhorias que conseguimos levantar em nosso diagnóstico, levando em consideração o número de páginas web e a estratégia de marketing digital em curso, incluindo todas as suas peças como formulários, landing-pages, CTAs, dentre outras.</p>

        <p style="font-size: 15px">
          <strong>Resultado</strong>
        </p>
        <p>
          Garantir a otimização do ambiente digital para que este esteja funcionando de acordo com as melhores práticas em SEO e de usabilidade. O trabalho de SEO deverá ser sempre focado no caminho que o usuário percorre até o momento em que ele manifesta interesse na aquisição do seu serviço e/ou produto.</p>
        <p style="font-size: 16px;"><strong>2 - Evolução</strong></p>
        <p>
          A metodologia evolutiva aplicada na InCuca não é somente focada na ideia de solucionar problemas, mas na ideia de analisar e identificar oportunidades que possibilitem a inovação do seu produto digital tendo o usuário como o centro de todas as estratégias. Para isso devemos analisar, planejar, mensurar e corrigir todas as nossas ações de trabalho.</p>
        <p style="font-size: 16px;"><strong>2.1 - Análise e Planejamento</strong></p>

        <p style="font-size: 15px">
          <strong>Objetivo</strong>
        </p>
        <p>
          Tendo garantido que a base técnica do SEO já foi aplicada ao ambiente digital, iniciamos o trabalho de estudo e planejamento do projeto a partir de uma análise de mercado para identificar o cenário atual, desafios e oportunidades existentes.</p>

        <p style="font-size: 15px">
          <strong>Como funciona</strong>
        </p>
        <p>
          Esta segunda etapa apoia-se num trabalho de pesquisa de palavras-chave (head tails e long tails), persona e público-alvo, benchmarking SEO (análise competitiva de posicionamento), fontes de tráfego e histórico do domínio. É com base nesse levantamento de dados e estudo que montamos o projeto de SEO. A partir daí detalhamos os principais objetivos, cronograma, estratégias e métricas de desempenho e metas a serem conquistadas.<br>
          De nada adianta ter um projeto de SEO se não temos como garantir que as visitas via tráfego orgânico, dentre outras fontes de origem, vão se transformar em novas oportunidades de negócio, e é por isso que trabalhamos em paralelo um planejamento de conversão. O estudo para combinar as estratégias de SEO com a geração de oportunidades baseia-se numa análise dos dados de audiência, tráfego, fluxo de navegação, taxa de rejeição (bounce rate), taxa de conversão (conversion rate optimization) e número de leads atuais do website e projeções futuras. <br>
          O projeto de conversão também incorpora testes A/B para as principais páginas de acesso e de captação, no sentido de validar fluxo de informação, fluxo de navegação, títulos e headlines, design (cores, tipografia), layout e imagens. <br>
          ;quebra;<br>
          A implementação do projeto de SEO e conversão é feita com base na contratação dos entregáveis executados no plano de banco de horas ou contratados separadamente.</p>

        <p style="font-size: 15px">
          <strong>Resultado</strong>
        </p>
        <p>
          Plano de SEO com detalhamento dos objetivos, estratégias, metas e cronograma de atividades definidos juntamente com cliente para aumento de tráfego e geração de oportunidades. Relatório de métricas de audiência, tráfego e conversão do estado atual do ambiente digital. </p>

        <p style="font-size: 16px">
          <strong> 2.2 - Monitoramento / Web Analytics</strong>
        </p>

        <p style="font-size: 15px">
          <strong>Objetivo</strong>
        </p>
        <p>
          Todo o nosso trabalho de SEO e conversão é feito com base em análise de dados. Extraímos insights de um alto volume de informação para identificar as tendências, oportunidades e comportamento do mercado e público-alvo relacionado ao seu negócio na internet. Por isso, tão importante quanto implementar um projeto de SEO e conversão é fazer o seu monitoramento para testar e validar as estratégias de ação no intuito de extrair o melhor resultado possível.</p>

        <p style="font-size: 15px">
          <strong>Como funciona</strong>
        </p>
        <p>
          A geração de insights e mensuração de dados é feita com base em ferramentas como Google Analytics, Google Search Console, Google Optimize e Heatmap.</p>

        <p style="font-size: 15px">
          <strong>Resultado </strong>
        </p>
        <p>
          Mantemos o cliente sempre atualizado com o envio de um relatório mensal das principais métricas e discutimos os resultados e o andamento do trabalho nos encontros de consultoria mensal.</p>

        <p style="font-size: 16px">
          <strong>2.3 - Manutenção Evolutiva</strong>
        </p>
        <p>
          Todo negócio digital precisa se manter atualizado frente às novas tecnologias e comportamento do seu público, e com SEO não é diferente. Motores de busca como o Google estão sempre em busca de melhorias para entregar o melhor resultado para o seu usuário, e em função disso sempre atualizam seus algoritmos e criam novos recursos dentro da sua SERP (página de resultados da busca). O que exige dos ambientes digitais um trabalho de atualização constante em relação às otimizações que devem ser implementadas.</p>

        <p style="font-size: 15px">
          <strong>Objetivo</strong>
        </p>
        <p>
          O trabalho de manutenção é vital para não perdermos posicionamento nos motores de busca e continuarmos alcançando novos espaços no rankeamento das pesquisas, além de se manter atualizado em relação às mudanças dos buscadores.</p>
        <p style="font-size: 15px">
          <strong>Como funciona</strong>
        </p>
        <p>
          A rotina de análise e monitoramento demanda ações de correção de qualquer desvio identificado em relação ao não cumprimento dos objetivos e metas estabelecidas no projeto. Outro ponto importante dessa frente é garantir que todos atores envolvidos no processo do trabalho de atualização do ambiente digital estejam alinhados com o projeto de SEO e conversão implementado. </p>
        ;quebra;
        <p
          class="pt-5"
          style="font-size: 15px">
          <strong>Resultado</strong>
        </p>
        <p>
          Ambiente sempre atualizado com as melhores práticas de SEO e conversão garantindo a obtenção dos resultados esperados de acordo com as metas estabelecidas para o projeto.</p>
        <p
          style="font-size: 18px"
          class="text-center"
        >
          <strong>O serviço de Evolução Digital é cíclico e funciona em 4 etapas: <br>
            Planejamento; Execução; Avaliação e Novas Ações</strong>
        </p>

        <p style="font-size: 15px;text-decoration:underline">
          <strong>Etapa 1 - Planejamento</strong>
        </p>
        <p>
          O primeiro passo é <strong>planejar a evolução da sua empresa</strong> no mundo digital. Nosso especialista em Gestão de Projetos e Negócios senta na mesa com você para priorizarem juntos as atividades que serão executadas.</p>

        <p style="font-size: 15px;text-decoration:underline">
          <strong>Etapa 2 - Execução</strong>
        </p>
        <p>
          Com as atividades priorizadas, nosso time é acionado para começar a evoluir seu negócio. Nessa etapa todo o time pode se envolver, de acordo com as demandas priorizadas por você e pela InCuca.</p>

        <p style="font-size: 15px;text-decoration:underline">
          <strong>Etapa 3 - Avaliação</strong>
        </p>
        <p>
          A cada ciclo de evolução a InCuca apresenta a você os resultados obtidos e verificar o que mais deu certo para que possa ser replicado em novas ações.</p>

        <p style="font-size: 15px;text-decoration:underline">
          <strong>Etapa 4 - Novas Ações </strong>
        </p>
        <p>
          Com os dados do que deu certo e um novo diagnóstico do seu ambiente digital, a InCuca vai planejar novas ações e apontar quais resultados são esperados. O ciclo de evolução recomeça!</p>

        <p style="font-size: 15px;">
          <strong>Pilares da Evolução</strong>
        </p>
        <p style="text-decoration:underline">
          Os pilares que irão nos guiar na condução do trabalho, são:</p>

        <p>Manutenção e Hospedagem<br>
          Estruturação de dados<br>
          Usabilidade<br>
          SEO, Performance e Segurança<br>
          Conversão<br>
          Novas Soluções<br>
          Tecnologias Disruptivas</p>
        ;quebra;
        <h4 class="pt-5 exo2 text-center website">
          <strong>METODOLOGIA</strong>
        </h4>

        <p>
          Para o desenvolvimento do trabalho usamos de diversas metodologias, e vamos encaixando a melhor que se enquadre na necessidade do cliente. <br>
          O consumo das horas propostas envolvem design, programação e fluxo de atendimento como um todo.<br>
          A produção irá debitar do banco de horas do cliente todo e qualquer tipo de investimento para que o problema, dor ou necessidade do cliente seja resolvido, desde uma reunião de alinhamento, análise de novas solicitações, programação, avaliação de ambientes, design, etc.</p>

        <p style="text-decoration:underline">Itens a serem considerados que serão debitados das horas propostas:</p>

        <p>
          - Planejamento e gestão do backlog do cliente/produto<br>
          - Pesquisa e análise oriundas de solicitações do cliente<br>
          - Desenvolvimento (Design, Programação, Testes, Treinamento)<br>
          - Reuniões/Comunicação/Atendimento ao cliente<br>
          - Manutenção do Ambiente de Homologação<br>
          - Manutenção do Ambiente de Produção<br>
          - Correção e avaliação de Bugs<br>
          - Documentação imposta pelo contrato ou solicitada pelo cliente</p>

        <p><strong>O cronograma deve ser estabelecido pelo Gerente de Projetos da InCuca.</strong><br>
          A execução do trabalho se dá remotamente. Nas etapas que envolvem reuniões, as mesmas ocorrem online (Google meeting ou outras ferramentas) e têm duração de 1 hora. <br>
          Se o cliente requerer atendimento InCompany é cobrado a parte (por hora) após o atendimento (contando a hora de saída InCuca até a hora de retorno).</p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-4">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>PROPOSTA COMERCIAL</strong>
        </h4>
        <p>A contratação do serviço se dá através do regime de banco de horas. O indicado para viabilizar o trabalho é de <strong>{{ budget.projectDescriptionValue }} horas/mês</strong>.</p>
        <p style="text-decoration:underline"><strong>Investimento mensal: {{ totalPrice }}</strong></p>
        <p>Forma de pagamento: Boleto bancário</p>
        <p>Vencimento: Dia 01 de cada mês</p>
        <p>Não há fidelidade. Solicitações de cancelamento devem ser feitas com 30 dias de antecedência e as cobranças nesse ínterim devem ser quitadas.</p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center ecommerce">
          <strong>IMPORTANTE</strong>
        </h4>
        <p>Essa proposta não contempla produção de conteúdo, gestão de mídias pagas e gestão de redes sociais. Nesse caso, podemos contar com os nossos parceiros e indicá-los na execução dessas atividades.</p>
        <p>Saiba mais sobre evolução digital: <a href="https://incuca.net/evolucao-digital-como-e-esse-servico-da-incuca/">https://incuca.net/evolucao-digital-como-e-esse-servico-da-incuca/.</a></p>
        <p>Estando de acordo com as condições desta proposta, precisaremos dos seguintes dados para emissão de nota fiscal:</p>
        <p>CNPJ, Razão Social, Endereço, Nome completo do representante legal e CPF do mesmo.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PartialEntendimentoDasNecessidades from './PartialEntendimentoDasNecessidades.vue';
import PartialSobreAInCuca from './PartialSobreAInCuca.vue';

export default {
  components: {PartialEntendimentoDasNecessidades, PartialSobreAInCuca},
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    totalPrice() {
      return this.budget.valueBudget.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>

<style>
</style>
