export function setBudgets(state, budgets) {
  state.budgets = budgets;
}
export function setBudget(state, budget) {
  state.budget = budget;
}
export function setbudgetCategoryId(state, catId) {
  state.budgetCategoryId = catId;
}
export function setBudgetCategories(state, budgetCategories) {
  state.budgetCategories = budgetCategories;
}
export function patchBudget(state, budget) {
  const client = {...state.budget.client, ...budget.client};
  state.budget = {...state.budget, ...budget, client};
}
export function setPersons(state, persons) {
  state.persons = persons;
}
export function hospedagens(state, data) {
  state.hospedagens = data;
}

export function patchEscopoAttributes(state, {event, item}) {
  if (item === 'category') {
    state.budget.budgetCategoryId = event;
  } else if (state.budget && state.budget.scope.steps) {
    if (item === 'treinamento') {
      state.budget.scope.steps.treinamento.horas = event;
    } else if (item === 'funcionalidades') {
      state.budget.scope.steps[item] = event;
    } else if (item === 'redirects') {
      state.budget.scope.steps[item].quantidade = event;
    } else {
      state.budget.scope.steps[item].categoria = event;
    }
  }
}

export function patchStepAttributes(
  state,
  {
    stepCategory,
    stepName,
    valor = 1,
  }
) {
  if (state.budget && state.budget.scope.steps) {
    const setpNameTmp = state.budget.scope.steps[stepName];
    if (stepCategory === 'basico') {
      setpNameTmp.basico.valor = valor;
      setpNameTmp.intermediario.valor = 0;
      setpNameTmp.avancado.valor = 0;
      if (
        stepCategory === state.budget.scope.steps.plataforma &&
        state.budget.budgetCategory.name === 'E-Commerce'
      ) {
        setpNameTmp.basico.peso = 11;
      } else if (
        stepCategory === state.budget.scope.steps.plataforma &&
        state.budget.budgetCategory.name !== 'E-Commerce'
      ) {
        setpNameTmp.basico.peso = 8;
      }
    } else if (stepCategory === 'intermediario') {
      setpNameTmp.basico.valor = 0;
      setpNameTmp.intermediario.valor = valor;
      setpNameTmp.avancado.valor = 0;
    } else if (stepCategory === 'avancado') {
      setpNameTmp.basico.valor = 0;
      setpNameTmp.intermediario.valor = 0;
      setpNameTmp.avancado.valor = valor;
    } else {
      throw Error('Step category not found');
    }
  }
}

export function setStepExtraInfo(state, {item, $event}) {
  const bud = {...{}, ...state.budget};
  if (item === 'metodologia') {
    bud.scope.steps[item].entregas = $event;
  } else if (item === 'conteudo') {
    bud.scope.steps[item].idiomas = $event;
  } else if (item === 'migracaoDeConteudo' || item === 'gateway') {
    if ($event.horas) {
      bud.scope.steps[item].horas = $event.horas;
    }
    if ($event.textoAnalise) {
      bud.scope.steps[item].textoAnalise = $event.textoAnalise;
    }
  } else {
    bud.scope.steps[item].extraInfo = $event;
  }
  state.budget = bud;
}

export function siteMap(state, {id, hasBlog}) {
  const bud = {...{}, ...state.budget};
  if (!hasBlog) {
    bud.scope.mapa.blocks = [];
    bud.scope.steps.blog = false;
  }
  if (id === 1) {
    // blog
    bud.scope.mapa.stages = ['Home', 'Categoria', 'Materiais Ricos'];
    bud.scope.mapa.blocks = [{
      id: 0,
      status: 'Categoria',
      title: 'Post',
    }];
  } else if (id === 6) {
    // Website
    bud.scope.mapa.stages = ['Home', 'Sobre', 'Serviços', 'Contato'];
    if (hasBlog) {
      bud.scope.steps.blog = true;
      bud.scope.mapa.stages.push('Categoria', 'Materiais Ricos');
      bud.scope.mapa.blocks.push({
        id: 0,
        status: 'Categoria',
        title: 'Post',
      });
    }
  } else if (id === 4) {
    // E-commerce
    bud.scope.mapa.stages = [
      'Home',
      'Categoria',
      'Carrinho',
      'Checkout',
      'Loja',
      'Termos de uso',
      'Política de privacidade',
      'Minha conta',
      'Compra concluída',
    ];
    bud.scope.mapa.blocks = [{
      id: 0,
      status: 'Categoria',
      title: 'Produto',
    }];
    if (hasBlog) {
      bud.scope.steps.blog = true;
      bud.scope.mapa.stages.push('Categoria do Blog', 'Materiais Ricos');
      bud.scope.mapa.blocks.push({
        id: 1,
        status: 'Categoria do Blog',
        title: 'Post',
      });
    }
  } else if (id === 3) {
    // EAD
    bud.scope.mapa.stages = [
      'Home',
      'Grupo',
      'Curso',
      'Minha Conta',
    ];
    bud.scope.mapa.blocks = [{
      id: 0,
      status: 'Curso',
      title: 'Lição',
    }, {
      id: 1,
      status: 'Curso',
      title: 'Tópicos',
    }, {
      id: 2,
      status: 'Curso',
      title: 'Teste',
    }, {
      id: 3,
      status: 'Minha Conta',
      title: 'Certificado',
    }];
    if (hasBlog) {
      bud.scope.steps.blog = true;
      bud.scope.mapa.stages.push('Categoria do Blog', 'Materiais Ricos');
      bud.scope.mapa.blocks.push({
        id: 4,
        status: 'Categoria do Blog',
        title: 'Post',
      });
    }
  }
  const paginas = bud.scope.mapa.stages.length + bud.scope.mapa.blocks.length;
  bud.scope.steps.paginas.quantidade = paginas;
  state.budget = bud;
}

export function projectDescription(state, text) {
  const bud = state.budget;
  bud.projectDescription = text;
  state.budget = bud;
}

export function plugins(state, pluginsList) {
  state.plugins = pluginsList;
}

export function patchPluginAttributes(state, $event) {
  state.plugin = {...state.plugin, ...$event};
}

export function plugin(state, updatedPlugin) {
  state.plugin = updatedPlugin;
}

export function hospedagem(state, updatedHospedagem) {
  state.hospedagem = updatedHospedagem;
}

export function patchHospedagemAttributes(state, $event) {
  state.hospedagem = {...state.hospedagem, ...$event};
}

export function setStepAnalise(state, atts) {
  const bud = {...{}, ...state.budget};
  bud.scope.steps[atts.item][atts.att] = atts.event;
  state.budget = bud;
}

export function setHospedagem(state, provedorHospedagem) {
  const bud = {...{}, ...state.budget};
  bud.scope.steps.hospedagem.provedor = provedorHospedagem;
  state.budget = bud;
}
