<template>
  <div>
    <div class="text-center">
      <h4>Você quer um site responsivo
      (layout adaptado para qualquer tamanho de tela)?</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="centered">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.responsividade.categoria"
            buttons
            stacked
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="patchEscopoAttributes(
              {event: $event, item:'responsividade'}
          )">
            <b-form-radio value="basico">
              Responsividade do tema escolhido, sem alteração
            </b-form-radio>
            <b-form-radio value="intermediario">
              Reponsividade otimizada. Podem ser feitos ajustes na
              ordem de quebra de seções (direita ou esquerda)<br> e omissão
              de itens no mobile.
            </b-form-radio>
            <b-form-radio value="avancado">
              Criação iniciando no mobile first para depois adaptar ao desktop
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.centered {
  text-align: center;
}
</style>
