<template>
  <div>
    <div class="text-center">
      <h4>Qual hospedagem?</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="custom-form">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.hospedagem.categoria"
            buttons
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="
            patchEscopoAttributes({event:$event, item:'hospedagem'})">
            <b-form-radio value="zip">
              Não há (é entregue um .zip)
            </b-form-radio>
            <b-form-radio value="cliente">
              Fornecida pelo cliente
            </b-form-radio>
            <b-form-radio value="compartilhada">
              InCuca Compartilhada
            </b-form-radio>
            <b-form-radio value="cloud">
              InCuca Cloud
            </b-form-radio>
            <b-form-radio value="inmake">
              InMake
            </b-form-radio>
            <b-form-radio value="outro">
              Outro
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="budget.scope.steps.hospedagem.categoria === 'outro'"
      class="item-content">
      <h4>Informar o número de horas:</h4>
      <b-form-input
        id="qtd-hospedagems"
        :value="budget.scope.steps.hospedagem.horas"
        class="mb-15"
        type="number"
        size="lg"
        @input="setStepAnalise(
      {item: 'hospedagem', att: 'horas', event: $event})"/>
      <h4 style="margin-top: 20px;">Informar a descrição da análise:</h4>
      <wysiwyg
        :html="budget.scope.steps.hospedagem.textoAnalise"
        placeholder="Enter something"
        @change="setStepAnalise(
      {item: 'hospedagem', att: 'textoAnalise', event: $event})"/>
    </b-row>
    <b-row v-if="budget.scope.steps.hospedagem.categoria === 'cliente'">
      <b-col>
        <b-form-group label="Hospedagens">
          <ic-crud-dropdown
            ref="searchHospedagem"
            :selected-item="budget.scope.steps.hospedagem.provedor"
            :items="hospedagens"
            enable-search
            txt-single-entitity-name="Hospedagem"
            txt-plural-entitity-name="Hospedagens"
            disable-edit
            disable-add
            disable-delete
            disable-modals
            @select="onSelect"
            @search="onHospedagemSearch"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="budget.scope.steps.hospedagem.categoria === 'cliente'"
      class="button-add-hospedagem">
      <b-button @click="toggleAddHospedagem">
        <span v-if="!show">Adicionar nova Hospedagem</span>
        <span v-if="show">Esconder formulário</span>
      </b-button>
    </b-row>
    <b-row
      v-if="show"
      class="form-add-hospedagem">
      <b-form
        @submit="onSubmit">
        <!-- nome -->
        <b-form-group
          id="hospedagemName"
          label="Nome da Hospedagem:"
          label-for="hospedagemName">
          <b-form-input
            id="hospedagemNameInput"
            :value="hospedagem.name"
            type="text"
            required
            placeholder="Nome da hospedagem"
            @change="patchHospedagemAttributes({name: $event})"/>
        </b-form-group>
        <!-- link -->
        <b-form-group
          id="hospedagemLink"
          label="Link para página da hospedagem"
          label-for="hospedagemLink">
          <b-form-input
            id="hospedagemLinkInput"
            :value="hospedagem.link"
            type="text"
            required
            placeholder="Link para página da hospedagem"
            @change="patchHospedagemAttributes({link: $event})"/>
        </b-form-group>
        <!-- horas -->
        <b-form-group
          id="hospedagemHoras"
          label="Quantidade de horas para configurar:"
          label-for="hospedagemHoras">
          <b-form-input
            id="hospedagemHorasInput"
            :value="hospedagem.horas"
            type="number"
            required
            placeholder="Informe a quantidade de horas"
            @change="patchHospedagemAttributes({horas: $event})"/>
        </b-form-group>
        <!-- descricao -->
        <b-form-group
          id="hospedagemDescricao"
          label="Descrição da hospedagem:"
          label-for="hospedagemDescription">
          <b-form-textarea
            id="hospedagemDescricaoTextarea"
            :rows="3"
            :max-rows="6"
            :value="hospedagem.descricao"
            required
            placeholder="Insira uma descrição"
            @input="patchHospedagemAttributes({descricao: $event})"/>
        </b-form-group>
        <!-- submit -->
        <b-button
          type="submit"
          variant="primary">Cadastrar hospedagem</b-button>
      </b-form>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
      'hospedagens',
      'hospedagem',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
      'setStepAnalise',
      'setHospedagem',
      'patchHospedagemAttributes',
    ]),
    ...mapActions('upsertBudget', ['searchHospedagens', 'createHospedagem']),
    onInput($event, att) {
      this.setStepAnalise({
        item: 'hospedagem',
        event: $event,
        att,
      });
    },
    onSelect($event) {
      this.selectedHospedagem = $event.item;
      this.setHospedagem($event.item);
    },
    onHospedagemSearch(term) {
      this.searchHospedagens(term);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.createHospedagem();
    },
    toggleAddHospedagem() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
};
</script>

<style lang="scss">
.custom-form /deep/ span {
  white-space: normal !important;
}
.custom-form {
  text-align: center;
}
.obs {
  text-align: center;
  width: 100%;
}
h4 {
    margin-bottom: 10px;
}
.row.button-add-hospedagem {
    margin-top: 35px;
    margin-bottom: 30px;
}
.row.form-add-hospedagem form {
  width: 100%;
  max-width: 600px;
}
.row.form-add-hospedagem {
  margin-bottom: 50px;
}
</style>
