<template>
  <div>
    <div class="text-center">
      <h4>As imagens (fotos) do seu site, precisam ser editadas?</h4>
    </div>
    <b-row>
      <b-col>
        <b-form-group class="custom-form">
          <b-form-radio-group
            id="radios"
            :checked="budget.scope.steps.imagens.categoria"
            buttons
            stacked
            button-variant="outline-primary"
            size="lg"
            name="radioBtnStacked"
            @input="
            patchEscopoAttributes({event:$event, item:'imagens'})">
            <b-form-radio value="basico">
              Banco de imagens sem edição
            </b-form-radio>
            <b-form-radio value="avancado">
              Banco de imagens com filtros/efeitos
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="budget.scope.steps.imagens.categoria === 'avancado'">
      <p class="obs">Observação: Limitamos a até 5 imagens por
      página e o cliente é responsável pela compra, quando necessário.</p>
    </b-row>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'patchEscopoAttributes',
    ]),
  },
};
</script>

<style lang="scss">
.custom-form /deep/ span {
  white-space: normal !important;
}
.custom-form {
  text-align: center;
}
.obs {
  text-align: center;
  width: 100%;
}
</style>
