<template>
  <div>
    <div>
      <div class="fixed-bottom w-100 p-2 text-white bg-primary text-center">
        <strong>{{ formattedPrice }}</strong>
      </div>
    </div>
    <b-container>
      <b-row style="margin-top: 50px;margin-bottom:50px">
        <form-wizard
          ref="wizard"
          title=""
          subtitle=""
          scopeize="xs"
          back-button-text="Voltar"
          next-button-text="Próximo"
          shape="tab"
          @on-change="saveBudget"
          @on-complete="goToSimulator">
          <tab-content title="Informações">
            <TabInformacoes/>
          </tab-content>
          <!-- <tab-content
            v-if="isProducao"
            title="Metodologia">
            <TabMetodologia/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Conteúdo">
            <TabConteudo/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Treinamento">
            <TabTreinamento/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Responsividade">
            <TabResponsividade/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Conceito">
            <TabConceito/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Busca por imagens">
            <TabImagens/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Migração de conteúdo">
            <TabMigracao/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Funcionalidades, integrações e plugins">
            <TabFuncionalidades/>
          </tab-content>
          <tab-content
            v-if="budget.budgetCategoryId === 3 || budget.budgetCategoryId == 4"
            title="Gateway de Pagamento">
            <TabGatewayDePagamento/>
          </tab-content>
          <tab-content
            v-if="budget.budgetCategoryId == 4"
            title="Método de Entrega">
            <TabMetodoDeEntrega/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Hospedagem">
            <TabHospedagem/>
          </tab-content>
          <tab-content
            v-if="isProducao"
            title="Redirects">
            <TabRedirects/>
          </tab-content> -->
        </form-wizard>
      </b-row>
      <!-- <div
        v-if="isProducao">
        <h4 style="margin-top: 50px;">Sitemap</h4>
        <b-row>
          <b-col class="mb-1">
            Página menu: <input
              v-model="stage"
              type="text"
              @keyup.enter="addStage()">
            <b-btn @click="addStage()">Add</b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            Página interna: <input
              v-model="blockTitle"
              type="text"
              @keyup.enter="addBlock()">
            <b-btn @click="addBlock()">Add</b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Kanban
              :stages="budget.scope.mapa.stages"
              :blocks="budget.scope.mapa.blocks"
              class="kanban"
              @update-block="updateBlock"
              @deleteStage="deleteStage">
              <div
                v-for="block in budget.scope.mapa.blocks"
                :key="block.id"
                :slot="block.id">
                <button
                  type="button"
                  class="close float-right text-white"
                  aria-label="Close"
                  @click="removeBlock(block)">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div>
                  {{ block.title }}
                </div>
              </div>
            </Kanban>
          </b-col>
        </b-row>
      </div> -->
    </b-container>
  </div>
</template>

<script>
import store from '@/store';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import {FormWizard, TabContent} from 'vue-form-wizard';
import {mapGetters, mapActions} from 'vuex';
import Kanban from '@/components/Kanban/Kanban.vue';
// tabs contents needs to be imported one by one here, also on components as well
import TabInformacoes from './steps/TabInformacoes.vue';
import TabImagens from './steps/TabImagens.vue';
import TabConceito from './steps/TabConceito.vue';
import TabFuncionalidades from './steps/TabFuncionalidades.vue';
import TabResponsividade from './steps/TabResponsividade.vue';
import TabTreinamento from './steps/TabTreinamento.vue';
import TabHospedagem from './steps/TabHospedagem.vue';
import TabMigracao from './steps/TabMigracao.vue';
import TabConteudo from './steps/TabConteudo.vue';
import TabMetodologia from './steps/TabMetodologia.vue';
import TabEntrega from './steps/TabEntrega.vue';
import TabGatewayDePagamento from './steps/TabGatewayDePagamento.vue';
import TabMetodoDeEntrega from './steps/TabMetodoDeEntrega.vue';
import TabRedirects from './steps/TabRedirects.vue';

export default {
  components: {
    Kanban,
    FormWizard,
    TabContent,
    TabInformacoes,
    TabImagens,
    TabConceito,
    TabFuncionalidades,
    TabResponsividade,
    TabTreinamento,
    TabHospedagem,
    TabMigracao,
    TabConteudo,
    TabMetodologia,
    TabEntrega,
    TabGatewayDePagamento,
    TabMetodoDeEntrega,
    TabRedirects,
  },
  data() {
    return {
      stage: '',
      blockTitle: '',
      id: 1,
      valueTypo: '',
    };
  },
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
      'budgetCategories',
      'pointPrice',
      'isProducao',
      'budgetTotal',
    ]),
    ...mapGetters('pipedrive', ['persons']),
    formattedPrice() {
      return `Horas: ${this.budgetTotal.hours} |
      Valor: R$ ${(this.budgetTotal.price).toFixed(2)}`;
    },
  },
  mounted() {
    this.getPersons();
    this.$refs.wizard.activateAll(true);
  },
  methods: {
    ...mapActions('upsertBudget', [
      'upsert',
      'getBudget',
      'getBudgetCategories',
      'onRouteEnter',
      'onRouteUpdate',
    ]),
    ...mapActions('pipedrive', ['getPersons', 'searchPersons']),
    deleteStage(stage) {
      const index = this.budget.scope.mapa.stages.indexOf(stage);
      if (index !== -1) {
        this.budget.scope.mapa.stages.splice(index, 1);
      }
      this.budget.scope.mapa.blocks = this.budget.scope.mapa.blocks.filter(
        bl => bl.status !== stage
      );
      this.calculatePages();
      this.saveBudget();
    },
    addStage() {
      if (this.stage !== '') {
        this.budget.scope.mapa.stages.push(this.stage);
        this.stage = '';
        this.calculatePages();
        this.saveBudget();
      }
    },
    removeBlock(block) {
      const index = this.budget.scope.mapa.blocks.indexOf(block);
      if (index !== -1) {
        this.budget.scope.mapa.blocks.splice(index, 1);
      }
      this.calculatePages();
      this.saveBudget();
    },
    addBlock() {
      if (this.blockTitle !== '') {
        let block = {
          id: this.count(),
          status: this.budget.scope.mapa.stages[0],
          title: this.blockTitle,
        };
        this.budget.scope.mapa.blocks.push(block);
        this.calculatePages();
        this.saveBudget();
        block = {};
        this.blockTitle = '';
      }
    },
    updateBlock(id, status) {
      this.budget.scope.mapa.blocks.find(
        b => b.id === Number(id)
      ).status = status;
      this.saveBudget();
    },
    calculatePages() {
      this.budget.scope.steps.paginas.quantidade =
        this.budget.scope.mapa.stages.length +
        this.budget.scope.mapa.blocks.length;
    },
    count() {
      this.id += 1;
      return this.id;
    },
    saveBudget() {
      this.upsert(this.budget);
    },
    goToSimulator() {
      // if is Suporte change the route
      if (this.budget.budgetCategoryId === 8) {
        this.budget.valueBudget = 280;
        this.upsert(this.budget).then((newBudget) => {
          this.$router.push({
            name: 'Visualizar escopo',
            params: {id: newBudget.id},
          });
        });
      } else {
        this.budget.valueBudget = this.budgetTotal.price;
        this.saveBudget();
        this.$router.push({
          name: 'Simulador de orçamento',
          params: {id: this.budget.id},
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('upsertBudget/onRouteEnter', {to, next});
  },
  beforeRouteUpdate(to, from, next) {
    this.onRouteUpdate(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.container /deep/ .vue-form-wizard .wizard-tab-content {
  padding: 30px 0px 10px;
}
.container /deep/ .vue-form-wizard .fa-search {
  position: absolute;
  float: right;
  margin-top: -25px;
  right: 33px;
}

</style>
