export function persons(state) {
  if (!state.persons) return [];
  return state.persons;
}

export function deals(state) {
  if (!state.deals) return [];
  return state.deals;
}

export function dealFields(state) {
  if (!state.dealFields) return [];
  return state.dealFields;
}

export function chartData(state) {
  if (!state.chartData) return [];
  return state.chartData;
}

export function values(state) {
  if (!state.values) return [];
  return state.values;
}
export function teste(state) {
  if (!state.teste) return [];
  return state.teste;
}
