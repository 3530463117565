<template>
  <b-row>
    <b-col cols="8">
      <div class="text-center">
        <h4>Metodologia</h4>
      </div>
      <b-row>
        <b-col>
          <b-form-group class="custom">
            <b-form-radio-group
              id="radios"
              :checked="budget.scope.steps.metodologia.categoria"
              buttons
              stacked
              button-variant="outline-primary"
              size="lg"
              name="radioBtnStacked"
              @input="onRadioSelect($event)">
              <b-form-radio value="linear">
                Entrega linear (Design para programação)
              </b-form-radio>
              <b-form-radio value="continua">
                Entregas contínuas
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="budget.scope.steps.metodologia.categoria === 'continua'"
        class="item-content">
        <h3>Informar o número de entregas</h3>
        <b-form-input
          id="qtd-entregas"
          :value="budget.scope.steps.metodologia.entregas"
          class="mb-15"
          type="number"
          size="lg"
          @input="onInput($event)"/>
      </b-row>
    </b-col>
    <b-col>
      <h4>Informações</h4>
      <p>
        <strong>Entrega linear</strong> - Reunião inicial >
        Design > Programação > Entrega
      </p>
      <p>
        <strong>Entrega contínua</strong> - Cada entrega multiplica
        o valor da entrega linear.
      </p>
    </b-col>
  </b-row>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters('upsertBudget', [
      'budget',
      'settedCategoryLevel',
    ]),
  },
  methods: {
    ...mapMutations('upsertBudget', [
      'setStepTextoAnalise',
      'setStepExtraInfo',
      'patchEscopoAttributes',
    ]),
    onInput($event) {
      this.setStepExtraInfo({item: 'metodologia', $event});
    },
    onRadioSelect($event) {
      if ($event !== 'continua') {
        this.setStepExtraInfo({item: 'metodologia', $event: null});
      }
      this.patchEscopoAttributes({event: $event, item: 'metodologia'});
    },
  },
};
</script>

<style lang="scss" scoped>
.custom {
  text-align: center;
}
.item-content {
  margin: 30px 0;
}
.mb-15 {
  margin-bottom: 15px;
}
</style>

