<template>
  <div>
    <partial-sobre-a-in-cuca />
    <partial-entendimento-das-necessidades :budget="budget" />
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="pt-5 exo2 text-center website">
          <strong>PROPOSTA TÉCNICA e COMERCIAL</strong>
        </h4>
        <p>Estamos propondo o desenvolvimento do site na plataforma mantida pela InCuca, o InMake.<br>
          Nossa equipe fará design e programação e o cliente fica responsável pela parte de conteúdo (imagens, vídeos e textos).<br>
          Na entrega do projeto ministraremos um treinamento online para apresentar o painel de administração. Nesse painel, o cliente terá acesso para ele mesmo administrar o conteúdo, garantindo mais autonomia e menor custo, já que não precisará contratar profissionais para atualizações de conteúdo do site. <br>
          Por ser um SaaS (software as a service), o site será publicado no sistema InMake da InCuca com o domínio do cliente, não sendo possível apontar para outros servidores. <br>
          O domínio deve estar registrado para a publicação o site (.com, .br ou qualquer outra extensão de preferência). Sobre registro de domínio acesse <a
            href="https://incuca.net/registro-de-dominio-na-internet/"
            target="_blank"
          >https://incuca.net/registro-de-dominio-na-internet/</a>.
        </p>
        <p style="font-size: 16px">
          <strong>Itens do Escopo</strong>
        </p>
        <p style="font-size: 15px">
          <strong>Idiomas</strong>
        </p>
        <ul>
          <li>Português</li>
        </ul>
        <p style="font-size: 15px">
          <strong>Sitemap</strong>
        </p>
        <ul>
          <li>Home (seções falando sobre a empresa)</li>
          <li>Estrutura</li>
          <li>Áreas de Atuação (Representação e Transporte)</li>
          <li>Contato</li>
        </ul>
        <p>Site com 4 páginas. A nomenclatura de cada uma e as seções da mesma serão definidas em conjunto com o cliente.</p>
        <p><strong>Caso haja mais páginas a serem criadas, iremos considerar como um ítem fora do escopo e será cotado à parte</strong></p>
        <p style="font-size: 15px"><strong>Conteúdo Textual</strong></p>
        <p>Cliente enviará os textos em um idioma preenchendo o documento de conteúdo durante a fase de design. A InCuca irá colocar o conteúdo no projeto até o início da programação. Válido para todos os modelos de páginas criados. Caso tenham páginas a serem replicadas a partir do modelo criado, a inserção de conteúdo será cotada a parte.</p>
        ;quebra;
        <p
          class="mt-5"
          style="font-size: 15px"><strong>Imagens</strong></p>
        <p>Podemos utilizar imagens de bancos pagos e gratuitos (sem edições) e inserir no projeto, caso o cliente não tenha as próprias imagens. Limitamos a até 5 imagens por página.</p>
        <p
          style="font-size: 15px"><strong>Atendimento</strong></p>
        <p>Link para WhatsApp e Redes Sociais</p>
        <p style="font-size: 15px"><strong>Hospedagem</strong></p>
        <p>Incluso na mensalidade. Servidor em cloud, que garante mais performance para o site</p>
        <p style="font-size: 15px"><strong>Treinamento</strong></p>
        <p>Treinamento online de 1 hora para apresentar o projeto e como o usuário poderá fazer a gestão do conteúdo de forma independente</p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>PROPOSTA COMERCIAL</strong>
        </h4>
        <p>A contratação do CMS InMake se dá através da mensalidade.</p>
        <p>Os ajustes necessários iniciais para configurar a plataforma conforme escopo descrito acima demandam investimento pontual de {{ budget.projectDescriptionValue }} horas.</p>
        <p style="text-decoration:underline"><strong>Escopo: {{ totalPrice }} referentes a {{ budget.projectDescriptionValue }} horas para design e ajustes de layout. </strong></p>
        <p style="text-decoration:underline"><strong>Mensalidade CMS: R$ 280,00 referente a disponibilidade da plataforma.</strong></p>
        <p>Forma de pagamento: Boleto bancário</p>
        <p>Vencimento: Dia 01 de cada mês</p>
        <p>Não há fidelidade. Solicitações de cancelamento devem ser feitas com 30 dias de antecedência e as cobranças nesse ínterim devem ser quitadas.</p>
      </b-col>
    </b-row>
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>ENTREGA - METODOLOGIA</strong>
        </h4>
        <p>No desenvolvimento de Website a entrega é linear, seguindo um fluxo evolutivo.</p>
        <p style="text-decoration:underline">As etapas são:</p>
        <ul>
          <li>Briefing</li>
          <li>Estudo</li>
          <li><strong>Reunião Inicial</strong></li>
          <li>Wireframe</li>
          <li><strong>Aprovação do Wireframe (Reunião)</strong></li>
          <li>Projeto Gráfico</li>
          <li><strong>Aprovação do projeto gráfico (Reunião)</strong></li>
          <li>Assets</li>
          <li>Programação</li>
          <li>Treinamento e Entrega (Reunião)</li>
        </ul>
        <p><strong style="text-decoration:underline">Prazo de entrega:</strong> Estimado em 45 dias</p>
        <p>A execução do trabalho se dá remotamente. Nas etapas que envolvem reuniões, as mesmas ocorrem online (Google meeting ou outras ferramentas) e têm duração média de 1 hora.<br>
          Se o cliente requerer atendimento InCompany é cobrado a parte (por hora) após o atendimento (contando a hora de saída InCuca até a hora de retorno).
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-2">
      <b-col>
        <h4 class="exo2 text-center website">
          <strong>CONTRATAÇÃO e CONDIÇÕES DE PAGAMENTO</strong>
        </h4>
        <p>A contratação é online, tendo o contrato assinado de forma digital.</p>

        <p>O pagamento que corresponde ao projeto em si, pode ser parcelado em até 3 vezes (ato, 30 e 60 dias).</p>
        <p>Já o pagamento que corresponde à mensalidade da plataforma, é cobrado mediante boleto bancário com data de vencimento combinada com o cliente e de forma pré-paga.</p>

        <p>Estando de acordo com as condições desta proposta, precisaremos dos seguintes dados para emissão de nota fiscal:<br>
          CNPJ, Razão Social, Endereço, Nome completo do representante legal e CPF do mesmo. </p>

        <p class="mb-0"><strong>Outras solicitações não descritas nesse escopo deverão ser orçadas a parte.</strong></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PartialEntendimentoDasNecessidades from './PartialEntendimentoDasNecessidades.vue';
import PartialSobreAInCuca from './PartialSobreAInCuca.vue';

export default {
  components: {PartialSobreAInCuca, PartialEntendimentoDasNecessidades},
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    totalPrice() {
      return this.budget.valueBudget.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>

<style>
</style>
