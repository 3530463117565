export function setPersons(state, persons) {
  state.persons = persons;
}

export function setDeals(state, deals) {
  state.deals = deals;
}

export function setDealFields(state, dealFields) {
  state.dealFields = dealFields;
}

export function setValues(state, {id, qtd}) {
  state.values[id] = qtd;
}
export function setTeste(state, teste) {
  state.teste = teste;
}
