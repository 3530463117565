<template>
  <div>
    <partial-sobre-a-in-cuca />
    <partial-entendimento-das-necessidades :budget="budget" />
    ;quebra;
    <b-row class="pt-5">
      <b-col>
        <h4 class="exo2 text-center ecommerce">
          <strong>IMPORTANTE</strong>
        </h4>
        <p>Reforçamos que o saldo de horas proposto é uma estimativa com base no cenário do cliente e pela experiência com outras situações semelhantes. Não garantimos que o investimento proposto será suficiente para resolver todo o problema.</p>
        <p class="mb-0"><strong>Outras solicitações não descritas nesse escopo deverão ser orçadas a parte.</strong></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PartialEntendimentoDasNecessidades from './PartialEntendimentoDasNecessidades.vue';
import PartialSobreAInCuca from './PartialSobreAInCuca.vue';

export default {
  components: {PartialEntendimentoDasNecessidades, PartialSobreAInCuca},
  props: {
    budget: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>
